import { Button, Dropdown, Navbar } from "flowbite-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { User, MenuArrow, Spinner } from "./icons";
import Cookies from "js-cookie";
import LogoutAuth from "../services/firebaseActions/logoutAuth";
import { NavigationObj } from "../utilities/navigations";
import { LoginModal } from "../components/auth/loginModal";

export const Header = () => {
  let navigate = useNavigate();
  const [userImage, setUserImage] = useState(<User />);
  var userProfImg = Cookies.get("profImg");

  const handleLogout = () => {
    LogoutAuth(navigate, "/");
  };

  useEffect(() => {
    if (Cookies.get("profImg")) {
      setUserImage(<img alt="userIcon" src={userProfImg} />);
    }
  }, [userProfImg]);

  return (
    <div className="container px-2 lg:px-12">
      <Navbar fluid={true} rounded={true} id="mainNav">
        <Navbar.Brand href="/">
          <img
            src="/gom-logo.svg"
            alt="Go Marketing Logo"
            className="w-56 sm:mr-[50px]"
          />
        </Navbar.Brand>
        <div className="md:order-2 hidden md:flex sm:ml-auto">
          <div className="flex md:order-2">
            {Cookies.get("token") && localStorage.getItem("userEmail") ? (
              <Dropdown
                arrowIcon={false}
                inline={true}
                label={
                  <div className="overflow-hidden relative w-8 h-8 bg-gray-100 rounded-full dark:bg-gray-600">
                    {userImage}
                  </div>
                }
              >
                <Dropdown.Header>
                  <span className="block text-sm">
                    {localStorage.getItem("userName")}
                  </span>
                  <span className="block truncate text-sm font-medium">
                    {localStorage.getItem("userEmail")}
                  </span>
                </Dropdown.Header>

                <Dropdown.Item onClick={handleLogout}>Log out</Dropdown.Item>
              </Dropdown>
            ) : !Cookies.get("token") && !localStorage.getItem("userEmail") ? (
              <>
                <LoginModal btnText={"Sign In"} googleBtn={false} />
              </>
            ) : (
              <div className="overflow-hidden relative w-8 h-8 bg-gray-100 rounded-full dark:bg-gray-600">
                <Spinner />
              </div>
            )}
          </div>
        </div>

        <Navbar.Toggle />
        <Navbar.Collapse>
          {NavigationObj().map((item, index) => {
            return (
              <Navbar.Link key={index} href={item.href} id="navLinks">
                {item.subNav ? (
                  <Dropdown arrowIcon={true} inline={true} label={item.name}>
                    {item.subNav.map((sub, i) => {
                      return (
                        <Dropdown.Item
                          key={i}
                          onClick={() => {
                            navigate(sub.href);
                          }}
                        >
                          {sub.name} <MenuArrow />
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown>
                ) : (
                  item.name
                )}
              </Navbar.Link>
            );
          })}
          <div className="md:hidden">
            {Cookies.get("token") ? (
              <div className="bg-gray-50 p-4">
                <div className="flow-root rounded-md px-2 py-2 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50">
                  <span className="flex items-center">
                    <span className="text-sm font-medium text-gray-900">
                      {localStorage.getItem("userName")}
                    </span>
                  </span>
                  <span className="block text-sm text-gray-500">
                    {localStorage.getItem("userEmail")}
                  </span>
                </div>
                <div className="link-variant">
                  <Button color="light" onClick={handleLogout}>
                    Logout
                  </Button>
                </div>
              </div>
            ) : (
              <div className="p-4">
                <LoginModal btnText={"Sign In"} googleBtn={false} />
              </div>
            )}
          </div>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};
