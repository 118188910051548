import { Footer } from "flowbite-react";
import { useLocation } from "react-router-dom";
import { FooterItems } from "../utilities/navigations";
import { UPLOAD } from "../routes/routes";
import packageJson from "../../package.json";

export const SiteFooter = () => {
  const location = useLocation();
  const currentYear = new Date().getFullYear();

  return (
    <div
      className={
        location.pathname === `/${UPLOAD}`
          ? "container px-2 lg:px-12"
          : "container px-2 lg:px-12 !mt-48"
      }
    >
      <div className="p-0 md:px-4 py-0">
        <hr className="mt-6 md:mb-10 border border-gray-300 sm:mx-auto dark:border-border" />
      </div>
      <Footer bgDark={true}>
        <div className="w-full container">
          <div className="w-full py-6 px-4 sm:flex sm:items-center sm:justify-between">
            <Footer.Brand
              href="/"
              src="/gom-logo.svg"
              alt="Go Marketing Logo"
            />
          </div>
          <div className="grid w-full grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 py-8 px-6">
            {FooterItems().map((item, index) => {
              return (
                <div key={index}>
                  <Footer.Title title={item.title} />
                  <Footer.LinkGroup col={true}>
                    {item.contents.map((contents, index2) => {
                      return (
                        <Footer.Link
                          key={index2}
                          href={contents.href}
                          target="_blank"
                        >
                          {contents.name}
                        </Footer.Link>
                      );
                    })}
                  </Footer.LinkGroup>
                </div>
              );
            })}
          </div>
          <div className="w-full py-6 px-4 mt-5 sm:flex sm:items-center sm:justify-between">
            <div className="text-gray-500 text-sm">
              <span>All contents &#64; {currentYear}</span>
              <a
                href="https://www.gomarkets.com/"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                GO Markets.
              </a>
              <span> All rights reserved.</span>
            </div>
            <span className="text-gray-500 text-sm">
              v{packageJson.version}
            </span>
          </div>
        </div>
      </Footer>
    </div>
  );
};
