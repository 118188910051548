import html2canvas from "html2canvas";
import YoutubeImgDisplay from "./ImgDisplay/ImgDisplay";
import { useHandleChange } from "../../hooks/useHandleChange";

export const YoutubeImgGenerator = () => {
  const initialState = {
    content: "",
    randomImg: "/660x720-trading.jpg",
    subTextColor: "dark",
    form: "template1",
    lang: "en",
  };

  const { params, setParams, handleFieldChange, handleFormReset } =
    useHandleChange({
      ...initialState,
    });

  //Add Image from dropdown
  const handleSelect = (event) => {
    const selectedOption = event.image;
    setParams({ ...params, randomImg: selectedOption });
  };

  // Download JPEG image
  const handleJpeg = (e) => {
    e.stopPropagation();

    html2canvas(document.getElementById("gen-img"), {
      scale: 2,
      quality: 1,
      pixelRatio: 1,
      allowTaint: true,
    }).then(function (canvas) {
      var link = document.createElement("a");
      link.type = "image/jpeg";
      link.download = "youtube-img.jpeg";
      link.href = canvas.toDataURL("image/jpeg");
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
    });
  };

  return (
    <>
      <YoutubeImgDisplay
        handleChange={handleFieldChange}
        handleJpeg={handleJpeg}
        handleFormReset={handleFormReset}
        handleSelect={handleSelect}
        data={params}
      />
    </>
  );
};
