import html2canvas from "html2canvas";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { ImageConvertorStyle, WeekDays } from "../../utilities/utilityService";
import ImgDisplay from "./ImgDisplay/ImgDisplay";
import Webinars from "./ImgDisplay/webinars";
import Global from "./ImgDisplay/global";
import MarketMovers from "./ImgDisplay/market-movers";
import { useHandleChange } from "../../hooks/useHandleChange";
import { usePrevious } from "../../hooks/usePrevious";
import Branding from "./ImgDisplay/branding";
import { Info } from "../../layouts/icons";
import { Tooltip } from "flowbite-react";
import EcoCalendar from "./ImgDisplay/ecoCalendar";

const options = [
  { id: 1, name: "Analysis and Articles", value: "Analysis-Articles" },
  { id: 2, name: "Webinars or Seminars", value: "Webinar-Seminars" },
  { id: 3, name: "Global Campaigns", value: "Global-Campaigns" },
  { id: 4, name: "Branding Template", value: "Branding-Template" },
  { id: 5, name: "Market Movers", value: "Market-Movers" },
  { id: 6, name: "Eco Calendar", value: "Eco-Calendar" },
];

const ImgGenerator = (props) => {
  const dyParam = useParams();
  const prev = usePrevious(props);
  const date = new Date();

  const initialState = {
    topText: "",
    subText: "",
    presenterCreds: "",
    presenterName: "",
    date: "",
    time: "",
    subTextColor: "customRed",
    topTextSize: "medium",
    bottomText: "",
    form: dyParam.id ? dyParam.id : "",
    lang: "en",
    ctaText: "",
    //analysisArticles
    randomImg: "/go-default.jpeg",
    secondaryImg: "/profile.png",
    placeholderImg: "/placeholder.png",
    overlay: "overlay1",
    //global
    globalImg: "/global/1200x628-default-base.jpg",
    globalImg2: "/global/1080x1080-default-base.jpg",
    globaloverlay: "overlay1",
    //webinar
    webinarImg: "/go-default-webinar.jpg",
    webinaroverlay: "overlay1",
    //branding
    brandingImg: "/placeholder-large.png",
    brandingImg2: "/placeholder-large.png",
    brandingoverlay: "overlay1",
    //market-movers
    marketsoverlay: "overlay1",
    marketType: "forex",
    market: "",
    marfketDir: "up",
    pointsChange: 0,
    percentChange: 0,
    closePrice: 0,
    //eco-calendar
    ecoOverlay: "overlay1",
    ecoDateFrom: date.toISOString().split("T")[0],
    ecoDateTo: date.toISOString().split("T")[0],
    ecoGMT: "",
    ecoCount: 1,
    ecoBoth: false,
    ecoDetails: [
      {
        ecoEvent: "Some title",
        ecoMarketDirExp: "up",
        ecoMarketDirPrev: "up",
        ecoCountry: "/eco/Property 1=default.png",
        ecoExpected: "",
        ecoPrevious: "",
        ecoDay: WeekDays()[new Date().getDay()],
        ecoTime: date.toLocaleTimeString("en-US", {
          timeStyle: "short",
          hour12: false,
        }),
      },
    ],

    filename: "social-img",
  };

  const { params, setParams, handleFieldChange, handleFormReset } =
    useHandleChange({
      ...initialState,
    });

  const handleRouteState = () => {
    setParams({
      ...params,
      form: props.locationState ? props.locationState : "Analysis-Articles",
    });
  };

  useEffect(() => {
    if (prev && prev.locationState !== props.locationState) {
      handleRouteState();
    }
    if (params.form !== props.userData) {
      props.userData(params.form);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.form, props.locationState, prev]);

  //Add uploaded image
  const handleUploadbranding = (event) => {
    event.preventDefault();
    const { files } = event.target;
    const uploadFilebranding = URL.createObjectURL(files[0]);
    setParams({
      ...params,
      brandingImg: uploadFilebranding,
      brandingImg2: uploadFilebranding,
    });
  };

  //Add Image from dropdown
  const handleSelect = (event) => {
    const selectedOption = event.image;
    setParams({ ...params, randomImg: selectedOption });
  };

  //Add Image from dropdown
  const handleSelectAlt = (event) => {
    const selectedOptionAlt = event.image;
    setParams({ ...params, webinarImg: selectedOptionAlt });
  };

  //Add Image from dropdown
  const handleSelectGlobal = (event) => {
    const selectedOptionGlobal = event.image;
    if (params.globaloverlay === "overlay1") {
      setParams({ ...params, globalImg: selectedOptionGlobal });
    } else {
      setParams({ ...params, globalImg2: selectedOptionGlobal });
    }
  };

  //Add Image from dropdown
  const handleSelectbranding = (event) => {
    const selectedOptionbranding = event.image;
    if (params.brandingoverlay === "overlay1") {
      setParams({ ...params, brandingImg: selectedOptionbranding });
    } else {
      setParams({ ...params, brandingImg2: selectedOptionbranding });
    }
  };

  //Add Image from dropdown
  const handleSelectPresenter = (event) => {
    const selectedOptionPresenter = event.image;
    setParams({ ...params, secondaryImg: selectedOptionPresenter });
  };

  //add more eco calendar items
  const handleEcoItem = () => {
    const add = () => setParams({ ...params, ecoCount: params.ecoCount + 1 });

    const remove = () => {
      setParams({ ...params, ecoCount: params.ecoCount - 1 });
      params.ecoDetails.splice(-1);
    };

    return { add, remove };
  };

  const handleEcoChange = (e) => {
    const name = e.target.name;
    const [, fieldName, index] = name.match(/^(eco[A-Za-z]+)(\d+)$/);

    if (fieldName && index) {
      const ecoDetailsCopy = [...params.ecoDetails];

      if (!ecoDetailsCopy[index]) {
        ecoDetailsCopy[index] = initialState.ecoDetails[0];
      }

      ecoDetailsCopy[index] = {
        ...ecoDetailsCopy[index],
        [fieldName]: e.target.value,
      };
      setParams({ ...params, ecoDetails: ecoDetailsCopy });
    }
  };
  // Download JPEG image
  const handleJpeg = async (e) => {
    e.stopPropagation();
    let style = ImageConvertorStyle();

    html2canvas(document.getElementById("gen-img"), {
      quality: 1,
      pixelRatio: 1,
      scale: 2,
      allowTaint: true,
    }).then(function (canvas) {
      style.remove();
      var link = document.createElement("a");
      document.body.appendChild(link);
      link.type = "image/jpeg";
      link.download = `${params.filename}.jpeg`;
      link.href = canvas.toDataURL("image/jpeg");
      link.target = "_blank";
      link.click();
    });
  };

  return (
    <div className="container mx-auto px-2 sm:px-4 lg:px-12">
      <div className="grid grid-cols-12 sm:px-4">
        <div className="sm:col-span-8 col-span-12">
          <div className="formselect mb-12">
            <select
              value={params.form}
              onChange={handleFieldChange}
              className="st-1 w-96 bg-transparent text-main font-display text-2xl font-bold border-transparent cursor-pointer pl-0 focus:border-gogreen focus:ring-gogreen"
              id="demo-simple-select"
              label="Select Image Creator Template"
              name="form"
            >
              <option disabled={true} value="">
                Select Image Creator Template
              </option>
              {options.map((item, index) => {
                return (
                  <option key={index} value={item.value}>
                    {item.name}
                  </option>
                );
              })}
            </select>
            <div className="w-72">
              <label className="font-body text-sm font-normal text-sub w-full flex">
                Filename
                <Tooltip content="If no name is provided, the default name 'social-img' will be set">
                  <Info customStyle="w-4 ml-1 -mt-[1px]" />
                </Tooltip>
              </label>
              <input
                type="text"
                id="filename"
                name="filename"
                className="form-control w-full text-sm border-border mt-1.5 focus:border-gogreen focus:ring-gogreen"
                placeholder="social-img"
                value={
                  params.filename === initialState.filename
                    ? ""
                    : params.filename
                }
                onChange={handleFieldChange}
              />
            </div>
          </div>
        </div>
        {/* <div className="hidden sm:flex sm:justify-end col-span-12 sm:col-span-4">
            <TutorialModal url={"./social-image-gen-tut.mp4"} />
          </div> */}
      </div>
      {params.form === "Analysis-Articles" ? (
        <ImgDisplay
          handleChange={handleFieldChange}
          handleJpeg={handleJpeg}
          handleFormReset={handleFormReset}
          handleSelect={handleSelect}
          data={params}
        />
      ) : params.form === "Webinar-Seminars" ? (
        <Webinars
          handleChange={handleFieldChange}
          handleJpeg={handleJpeg}
          handleFormReset={handleFormReset}
          handleSelectAlt={handleSelectAlt}
          handleSelectPresenter={handleSelectPresenter}
          data={params}
        />
      ) : params.form === "Branding-Template" ? (
        <Branding
          handleChange={handleFieldChange}
          handleJpeg={handleJpeg}
          handleUploadbranding={handleUploadbranding}
          handleFormReset={handleFormReset}
          handleSelectbranding={handleSelectbranding}
          data={params}
        />
      ) : params.form === "Global-Campaigns" ? (
        <Global
          handleChange={handleFieldChange}
          handleJpeg={handleJpeg}
          handleFormReset={handleFormReset}
          handleSelectGlobal={handleSelectGlobal}
          data={params}
        />
      ) : params.form === "Market-Movers" ? (
        <MarketMovers
          handleJpeg={handleJpeg}
          handleChange={handleFieldChange}
          handleFormReset={handleFormReset}
          data={params}
        />
      ) : params.form === "Eco-Calendar" ? (
        <EcoCalendar
          handleJpeg={handleJpeg}
          handleChange={handleFieldChange}
          handleFormReset={handleFormReset}
          data={params}
          handleEcoItem={handleEcoItem}
          handleEcoChange={handleEcoChange}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default ImgGenerator;
