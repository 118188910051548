import { Badge, Card } from "flowbite-react";
import { Link } from "react-router-dom";

export const Cards = (props) => {
  return (
    <div className="max-w-sm tab-cards">
      <Link
        to={props.card.path}
        state={{ stateObj: { id: props.card.template } }}
      >
        <Card imgSrc={props.card.image}>
          <div className="flex justify-between">
            {props.card.new ? (
              <div className="tab-badge">
                <Badge color="info">New</Badge>
              </div>
            ) : (
              ""
            )}
            <div className="tab-badge">
              <Badge color="success">{props.card.category}</Badge>
            </div>
          </div>

          <h5 className="text-lg font-bold tracking-tight text-gray-900 dark:text-white">
            {props.card.cardTitle}
          </h5>
          <p className="font-normal text-md text-gray-700 dark:text-gray-400">
            {props.card.cardContent}
          </p>
        </Card>
      </Link>
    </div>
  );
};
