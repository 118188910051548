import { Select } from "flowbite-react";
import { useLocation } from "react-router-dom";
import { HistoryCard } from "../../layouts/fileUploader/historyCard";
import { UploadForm } from "./uploadForm";
import { createContext, useContext, useEffect, useState } from "react";
import CopyClipboard from "../../layouts/fileUploader/copyClipboard";
import {
  CategoryObj,
  LegalCategories,
} from "../../utilities/fileUploader/objects";
import {
  C_DIVIDEND_FEED,
  C_GENERAL,
  C_LEGAL_DOCUMENTS,
  C_TRADING_HOURS,
} from "../../constants/types";
import { UploadContext } from "../../context/UploadProvider";

export const StorageContext = createContext();

export const UploadSection = () => {
  const location = useLocation();
  const [formType, setFormType] = useState("");
  const [legalCategory, setLegalCategory] = useState(
    LegalCategories()[0].value
  );
  const [filter, setFilter] = useState("all");
  const { data, isLoading } = useContext(UploadContext);

  useEffect(() => {
    if (location.state) {
      CategoryObj().some(
        (e) => e.value === location.state.value && setFormType(e.value)
      );
    } else {
      CategoryObj().some(
        (e) => location.pathname.includes(e.routes) && setFormType(e.value)
      );
    }
  }, [location.state, location]);

  const handleLegalCategory = (e) => {
    e.preventDefault();
    setLegalCategory(e.target.value);
  };

  // to reset the filter value on state change
  useEffect(() => {
    setFilter("all");
  }, [location]);

  return (
    <StorageContext.Provider value={{ legalCategory, isLoading }}>
      <div className="grid gap-2 grid-cols-1 lg:grid-cols-2 w-full ">
        <div className="w-full">
          <div className="flex gap-16">
            <h2 className="block mb-4 text-3xl font-bold tracking-tight text-secondary">
              Upload new
            </h2>

            {formType === C_LEGAL_DOCUMENTS && (
              <Select
                id="category"
                required={true}
                defaultValue={LegalCategories()[0].value}
                onChange={handleLegalCategory}
              >
                <option value="" disabled>
                  Choose here
                </option>
                {LegalCategories().map((item, index) => {
                  return (
                    <option key={index} value={item.value}>
                      {item.category}
                    </option>
                  );
                })}
              </Select>
            )}
          </div>

          <UploadForm formType={formType} />
        </div>
        <div className="w-full text-start">
          <div className="flex justify-between">
            <h2 className="block mb-4 text-3xl font-bold tracking-tight text-secondary">
              Upload details{" "}
              {formType === C_LEGAL_DOCUMENTS && (
                <span className="bg-green-100 text-primary text-sm font-medium mr-2 px-2.5 py-0.5 rounded">
                  {legalCategory}
                </span>
              )}
            </h2>
            {(formType === C_DIVIDEND_FEED || formType === C_TRADING_HOURS) && (
              <CopyClipboard data={data} formType={formType} />
            )}

            {(formType === C_GENERAL || formType === C_LEGAL_DOCUMENTS) && (
              <Select
                id="category"
                defaultValue={"all"}
                onChange={(e) => {
                  setFilter(e.target.value);
                }}
              >
                <option value="all">All (default)</option>
                <option value="userUploaded">Uploaded by you</option>
              </Select>
            )}
          </div>
          <HistoryCard data={data} formType={formType} filter={filter} />
        </div>
      </div>
    </StorageContext.Provider>
  );
};
