import html2canvas from "html2canvas";
import EmailImgDisplay from "./ImgDisplay/ImgDisplay";
import { useHandleChange } from "../../hooks/useHandleChange";

export const EmailImgGenerator = () => {
  const initialState = {
    topText: "",
    topTextSize: "large",
    randomImg: "/650-metatrader.jpg",
    overlay: "overlay1",
    form: "template1",
    lang: "en",
  };
  const { params, setParams, handleFieldChange, handleFormReset } =
    useHandleChange({
      ...initialState,
    });

  //Add Image from dropdown
  const handleSelect = (event) => {
    const selectedOption = event.image;
    setParams({ ...params, randomImg: selectedOption });
  };

  // Download JPEG image
  const handleJpeg = (e) => {
    e.stopPropagation();

    html2canvas(document.getElementById("gen-img"), {
      quality: 1,
      pixelRatio: 1,
      cacheBust: true,
      canvasWidth: 650,
      width: 650,
      canvasHeight: 400,
      height: 400,
      allowTaint: true,
    }).then(function (canvas) {
      var link = document.createElement("a");
      link.type = "image/jpeg";
      link.download = "email-header-img.jpeg";
      link.href = canvas.toDataURL("image/jpeg");
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
    });
  };

  return (
    <>
      <EmailImgDisplay
        handleChange={handleFieldChange}
        handleJpeg={handleJpeg}
        handleFormReset={handleFormReset}
        handleSelect={handleSelect}
        data={params}
      />
    </>
  );
};
