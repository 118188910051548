import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import Cookies from "js-cookie";

/**
 * Validates users' GO Markets gmail credentials to allow access.
 *
 * Note: This API is requires firebase SDK setup on the project.
 *
 */
const LoginAuth = async (navigate, navPath, setStatus, setIsLoading) => {
  const auth = getAuth();
  const provider = new GoogleAuthProvider();
  setIsLoading(true);
  await signInWithPopup(auth, provider)
    .then((result) => {
      setIsLoading(false);
      // The signed-in user info.
      const user = result.user;
      Cookies.set("token", result._tokenResponse.idToken, {
        secure: true,
        sameSite: "strict",
      });
      Cookies.set("userId", user.uid, {
        secure: true,
        sameSite: "strict",
      });

      localStorage.setItem("userName", user.displayName);
      localStorage.setItem("userEmail", user.email);
      Cookies.set("profImg", user.photoURL, {
        secure: true,
        sameSite: "strict",
      });

      if (
        parseFloat(user.metadata.lastLoginAt) -
          parseFloat(user.metadata.createdAt) <=
        1200000
      ) {
        localStorage.setItem("newUser", true);
      } else {
        localStorage.setItem("newUser", false);
      }

      navigate(navPath);
    })
    .catch((error) => {
      setIsLoading(false);
      // Handle Errors here.
      const errorCode = error.code;
      setStatus(errorCode);
      // console.log(errorCode);
    });
};

export default LoginAuth;
