import { useState } from "react";

export function useHandleModal(initialValue) {
  let [isOpen, setIsOpen] = useState(initialValue);

  const closeModal = () => {
    setIsOpen(false);
  };
  const openModal = () => {
    setIsOpen(true);
  };

  return {
    isOpen,
    closeModal,
    openModal,
  };
}
