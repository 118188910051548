import { useEffect, useState } from "react";

export const TranslateInput = (text, fromLang, toLang) => {
  const [translatedText, setTranslatedText] = useState("");

  const API_KEY = ["AIzaSyA38gQdlp66jHMK2y4lWoZCuJoc6vKNVRE"];

  let url = `https://translation.googleapis.com/language/translate/v2?key=${API_KEY}`;
  url += "&q=" + encodeURI(text);
  url += `&source=${fromLang}`;
  url += `&target=${toLang}`;

  useEffect(() => {
    //only translate if selected language is not english
    if (toLang !== "en" && toLang !== "") {
      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((res) => res.json())
        .then((response) => {
          //console.log("response from google: ", response);
          setTranslatedText(response.data.translations[0].translatedText);
        })
        .catch((error) => {
          console.log(
            "There was an error with the translation request: ",
            error
          );
        });
    }
  }, [url, text, toLang]);

  return translatedText;
};

export const DetectLanguage = (text) => {
  const [detectedLang, setDetectedLang] = useState("");

  const API_KEY = ["AIzaSyA38gQdlp66jHMK2y4lWoZCuJoc6vKNVRE"];

  let url = `https://translation.googleapis.com/language/translate/v2/detect?key=${API_KEY}`;
  url += "&q=" + encodeURI(text);

  useEffect(() => {
    if (text !== "") {
      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((res) => res.json())
        .then((response) => {
          setDetectedLang(response.data.detections[0][0].language);
        })
        .catch((error) => {
          console.log(
            "There was an error with the translation request: ",
            error
          );
        });
    }
  }, [url, text]);

  return detectedLang;
};
