import React from "react";
import { country, gmt } from "../../../utilities/imgGenerator/ecoCalendarObj";
import {
  DateOrdinal,
  Months,
  WeekDays,
} from "../../../utilities/utilityService";

function EcoCalendar(props) {
  const { add, remove } = props.handleEcoItem();
  var days = WeekDays();
  const date = new Date();
  var months = Months();

  const dateRange = (from, to) => {
    const date = new Date(from);
    const date2 = new Date(to);
    let timeZone =
      "GMT" +
      (date.getTimezoneOffset() / 60 < 0 ? "+" : "-") +
      Math.abs(date.getTimezoneOffset() / 60);

    let dateRange = `${[days[date.getDay()]]} ${DateOrdinal(date.getDate())} ${
      months[date.getMonth()]
    } - ${[days[date2.getDay()]]} ${DateOrdinal(date2.getDate())} ${
      months[date2.getMonth()]
    }`;

    let gmt = props.data.ecoGMT ? props.data.ecoGMT : timeZone;

    return { dateRange, gmt };
  };

  return (
    <div className="ecoCalendar">
      <div className="grid grid-cols-12 gap-8 sm:px-4">
        <div className="col-span-12 sm:col-span-12 lg:col-span-5">
          <p className="mb-6">
            Create and customise your image by using the fields below.
          </p>

          <form>
            <div>
              <h3 className="font-display text-lg font-bold text-sub mb-7">
                Image Settings
              </h3>

              <div className="image-details">
                <div className="flex justify-left">
                  <label
                    id="demo-radio-buttons-group-label"
                    className="font-body text-sm font-normal text-sub mr-9"
                  >
                    Image size
                  </label>
                  <div className="form-check form-check-inline mr-5">
                    <input
                      className="form-check-input form-check-input rounded-full h-4 w-4 text-gogreen bg-gray-100 border-gray-300 focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 float-left mr-2 mt-1 align-top cursor-pointer"
                      type="radio"
                      name="ecoOverlay"
                      id="inlineRadio1"
                      value="overlay1"
                      onChange={props.handleChange}
                      defaultChecked
                    />
                    <label
                      className="form-check-label inline-block font-body text-sm font-normal text-sub"
                      htmlFor="inlineRadio20"
                    >
                      1080x1080
                    </label>
                  </div>
                  <div className="form-check form-check-inline mr-5">
                    <input
                      className="form-check-input form-check-input rounded-full h-4 w-4 text-gogreen bg-gray-100 border-gray-300 focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 float-left mr-2 mt-1 align-top cursor-pointer"
                      type="radio"
                      name="ecoOverlay"
                      id="inlineRadio2"
                      value="overlay2"
                      onChange={props.handleChange}
                    />
                    <label
                      className="form-check-label inline-block font-body text-sm font-normal text-sub"
                      htmlFor="inlineRadio20"
                    >
                      1080x1920
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-8">
              <h3 className="font-display text-base font-bold text-sub mb-1">
                Image Details
              </h3>
              <p className="mb-3">Enter date range below</p>

              <div className="flex gap-1">
                <div className="block">
                  <label
                    className="font-body text-sm font-normal text-su block"
                    htmlFor="ecoDateFrom"
                  >
                    From
                  </label>

                  <input
                    type="date"
                    className="form-control mb-2 w-22 text-sm border border-border mt-1.5 focus:border-gogreen focus:ring-gogreen"
                    name="ecoDateFrom"
                    value={
                      props.data?.ecoDateFrom ||
                      date.toISOString().split("T")[0]
                    }
                    onChange={props.handleChange}
                  />
                </div>

                <div className="block">
                  <label
                    className="font-body text-sm font-normal text-sub block"
                    htmlFor="ecoDateFrom"
                  >
                    To
                  </label>
                  <input
                    type="date"
                    className="form-control mb-2 w-22 text-sm border border-border mt-1.5 focus:border-gogreen focus:ring-gogreen"
                    name="ecoDateTo"
                    value={
                      props.data?.ecoDateTo || date.toISOString().split("T")[0]
                    }
                    onChange={props.handleChange}
                  />
                </div>
                <div className="block">
                  <label
                    className="font-body text-sm font-normal text-sub block"
                    htmlFor="ecoGMT"
                  >
                    GMT
                  </label>
                  <select
                    defaultValue={"DEFAULT"}
                    onChange={props.handleChange}
                    className="form-control mb-2 w-22 text-sm border border-border mt-1.5 focus:border-gogreen focus:ring-gogreen"
                    id="simple-select"
                    label="GMT"
                    name="ecoGMT"
                  >
                    <option disabled={true} value="DEFAULT">
                      Select GMT
                    </option>
                    {gmt &&
                      [...gmt].map((item, index) => {
                        return (
                          <option key={index} value={item.value}>
                            {item.value}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
            </div>
            <div className="mt-8">
              <h3 className="font-display text-base font-bold text-sub mb-1">
                Event Details
              </h3>
              <p className="mb-6">Enter the calendar details below</p>

              {Array(props.data.ecoCount)
                .fill()
                .map((_, index) => {
                  return (
                    <div key={index}>
                      <div>
                        <label className="font-body text-sm font-normal text-sub">
                          Item {index + 1}
                        </label>
                      </div>
                      <div>
                        <select
                          defaultValue={"DEFAULT"}
                          onChange={props.handleEcoChange}
                          className="w-2/5 border-border mb-2 cursor-pointer text-sm focus:border-gogreen focus:ring-gogreen"
                          id="simple-select"
                          label="Select Country"
                          name={`ecoCountry${index}`}
                        >
                          <option disabled={true} value="DEFAULT">
                            Select Country
                          </option>
                          {country &&
                            [...country].map((item, index) => {
                              return (
                                <option key={index} value={item.image}>
                                  {item.name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                      <div className="flex gap-1">
                        <select
                          defaultValue={"DEFAULT"}
                          onChange={props.handleEcoChange}
                          className="form-control mb-2 w-22 text-sm border border-border mt-1.5 focus:border-gogreen focus:ring-gogreen"
                          id="simple-select"
                          label="Select day"
                          name={`ecoDay${index}`}
                        >
                          <option disabled={true} value="DEFAULT">
                            Select Day
                          </option>
                          {days &&
                            days.map((item, index) => {
                              return (
                                <option key={index} value={item}>
                                  {item}
                                </option>
                              );
                            })}
                        </select>

                        <input
                          type="text"
                          className="form-control mb-2 w-22 text-sm border border-border mt-1.5 focus:border-gogreen focus:ring-gogreen"
                          name={`ecoTime${index}`}
                          value={props.data.ecoDetails[index]?.ecoTime || ""}
                          onChange={props.handleEcoChange}
                          placeholder="Input time"
                        />
                        <input
                          className="form-control mb-2 w-[201px] text-sm border border-border mt-1.5 focus:border-gogreen focus:ring-gogreen"
                          type="text"
                          placeholder="Country/event"
                          name={`ecoEvent${index}`}
                          onChange={props.handleEcoChange}
                        />
                      </div>
                      <div className="flex gap-1 items-center">
                        <input
                          className="form-control mb-2 w-[6.5rem] text-sm border border-border mt-1.5 focus:border-gogreen focus:ring-gogreen"
                          type="text"
                          placeholder="Expected"
                          name={`ecoExpected${index}`}
                          onChange={props.handleEcoChange}
                        />
                        <input
                          className="form-control mb-2 w-[6.5rem] text-sm border border-border mt-1.5 focus:border-gogreen focus:ring-gogreen"
                          type="text"
                          placeholder="Previous"
                          name={`ecoPrevious${index}`}
                          onChange={props.handleEcoChange}
                        />
                      </div>
                    </div>
                  );
                })}

              <div className="flex justify-start w-100 gap-2">
                {props.data.ecoCount < 6 && (
                  <button
                    className="btn-primary px-3 py-2 !font-semibold  text-xs ease-linear transition-all duration-150 rounded "
                    type="button"
                    name="ecoCount"
                    onClick={add}
                  >
                    Add
                  </button>
                )}
                {props.data.ecoCount > 1 && (
                  <button
                    className="btn-primary px-3 py-2 !font-semibold  text-xs ease-linear transition-all duration-150 rounded "
                    type="button"
                    name="ecoCount"
                    onClick={remove}
                  >
                    Remove
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>

        <div className="col-span-7 sm:pl-0 lg:pl-11">
          {/* Preview component */}
          <div
            id="gen-img-preview"
            className={`DisplayImg ${props.data.ecoOverlay} DisplayImgPreview hidden sm:hidden lg:block`}
          >
            <div id="top">
              <div className="flex py-4 mr-5 badge-block">
                <span className="bg-primary text-[#fff] top-badge">
                  {
                    dateRange(props.data.ecoDateFrom, props.data.ecoDateTo)
                      .dateRange
                  }
                  <span className="ml-3 text-[#F1F1F1]">
                    {
                      dateRange(props.data.ecoDateFrom, props.data.ecoDateTo)
                        .gmt
                    }
                  </span>
                </span>
              </div>
              <div className="flex items-center text-block">
                <p className="font-inter">Economic Events</p>
              </div>
            </div>

            <div id="center" className="py-2 px-6 list-items">
              <div
                className="grid grid-cols-7 gap-1 text-[#F0F3FA]"
                id="header"
              >
                <div>Day/Time</div>
                <div className="col-span-4">Country/Event</div>
                <div>Expected</div>
                <div>Previous</div>
              </div>

              {props.data.ecoDetails.map((detail, index) => {
                return (
                  <div
                    className="grid grid-cols-8 gap-1 text-white mt-2"
                    id="item"
                    key={index}
                  >
                    <div className="block">
                      <span className="block font-bold">{detail.ecoDay}</span>
                      <span>{detail.ecoTime}</span>
                    </div>
                    <div className="flex bg-[#FFFFFF1A] rounded-lg col-span-7 px-3 items-center col-wrapper">
                      <div className="flex -space-x-1 overflow-hidden flag-container">
                        <img
                          className="inline-block h-[35px] w-[35px] rounded-full"
                          src={detail.ecoCountry}
                          alt={`country-${index}`}
                        />
                      </div>
                      <span className="country-event">{detail.ecoEvent}</span>
                      <div className="flex w-[100px] justify-center">
                        {detail.ecoExpected && (
                          <button
                            className={`badge  bg-transparent border-2  truncate ${
                              detail.ecoMarketDirExp === "up"
                                ? "text-[#0576DB] border-[#0576DB]"
                                : "text-[#FC293A] border-[#FC293A]"
                            }`}
                            id="ecoMarketDirExp"
                            name={`ecoMarketDirExp${index}`}
                            value={
                              detail.ecoMarketDirExp === "down" ? "up" : "down"
                            }
                            onClick={props.handleEcoChange}
                          >
                            {detail.ecoExpected}
                          </button>
                        )}
                      </div>
                      <div className="flex w-[100px] justify-center">
                        {detail.ecoPrevious && (
                          <button
                            className={`badge text-[#091F2C] truncate ${
                              detail.ecoMarketDirPrev === "up"
                                ? "bg-[#0576DB]"
                                : "bg-[#FC293A]"
                            }`}
                            id="ecoMarketDirPrev"
                            name={`ecoMarketDirPrev${index}`}
                            value={
                              detail.ecoMarketDirPrev === "down" ? "up" : "down"
                            }
                            onClick={props.handleEcoChange}
                          >
                            {detail.ecoPrevious}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div id="bottom" className="flex items-center">
              <img
                src="/go-logo-reverse@2x.png"
                className="ml-[35.95px]"
                alt="go-logo"
                width="299.02px"
              />
              <p className="mr-[35.95px]">gomarkets.com</p>
            </div>
          </div>

          {/* Generated component */}
          <div className="sr-only">
            <div
              id="gen-img"
              className={`DisplayImg ${props.data.ecoOverlay} lg:block`}
            >
              <div id="top">
                <div className="flex py-4 mr-5 badge-block">
                  <span className="bg-primary text-[#fff] top-badge">
                    {
                      dateRange(props.data.ecoDateFrom, props.data.ecoDateTo)
                        .dateRange
                    }
                    <span className="ml-3 text-[#F1F1F1]">
                      {
                        dateRange(props.data.ecoDateFrom, props.data.ecoDateTo)
                          .gmt
                      }
                    </span>
                  </span>
                </div>
                <div className="flex items-center text-block">
                  <p className="font-inter">Economic Events</p>
                </div>
              </div>

              <div id="center" className="py-2 px-6 list-items">
                <div
                  className="grid grid-cols-8 gap-1 text-[#F0F3FA]"
                  id="header"
                >
                  <div className="">Day/Time</div>
                  <div className="col-span-5">Country/Event</div>
                  <div className="">Expected</div>
                  <div className="">Previous</div>
                </div>

                {props.data.ecoDetails.map((detail, index) => {
                  return (
                    <div
                      className="grid grid-cols-8 gap-1 text-white"
                      id="item"
                      key={index}
                    >
                      <div className="block">
                        <span className="block day">{detail.ecoDay}</span>
                        <span className="time">{detail.ecoTime}</span>
                      </div>
                      <div className="flex bg-[#FFFFFF1A] rounded-lg col-span-7 px-4 items-center col-wrapper">
                        <div className="flex -space-x-1 overflow-hidden flag-container ">
                          <img
                            className="inline-block h-[50px] w-[50px] rounded-full"
                            src={detail.ecoCountry}
                            alt={`country-${index}`}
                          />
                        </div>
                        <span className="country-event">{detail.ecoEvent}</span>
                        <div className="flex w-[142.25px] justify-center">
                          {detail.ecoExpected && (
                            <span
                              className={`badge  bg-transparent border-2  truncate ${
                                detail.ecoMarketDirExp === "up"
                                  ? "text-[#0576DB] border-[#0576DB]"
                                  : "text-[#FC293A] border-[#FC293A]"
                              }`}
                            >
                              {detail.ecoExpected}
                            </span>
                          )}
                        </div>
                        <div className="flex w-[142.25px] justify-center">
                          {detail.ecoPrevious && (
                            <span
                              className={`badge text-[#091F2C] truncate ${
                                detail.ecoMarketDirPrev === "up"
                                  ? "bg-[#0576DB]"
                                  : "bg-[#FC293A]"
                              }`}
                            >
                              {detail.ecoPrevious}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>

              <div id="bottom" className="flex items-center px-6">
                <img
                  src="/go-logo-reverse@2x.png"
                  alt="go-logo"
                  width="453px"
                />
                <p>gomarkets.com</p>
              </div>
            </div>
          </div>
          {/* End of generated component */}
          <div className="button-wrapper">
            <button
              type="button"
              className="btn mt-11 rounded st-4"
              onClick={(e) => {
                props.handleFormReset(e);
              }}
            >
              Reset Preview
            </button>
            <button
              className="btn btn-primary ease-linear transition-all duration-150 rounded st-5"
              type="button"
              onClick={props.handleJpeg}
            >
              Generate Image
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EcoCalendar;
