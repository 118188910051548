import { TypeWriter } from "../utilities/utilityService";

export const Console = ({ text }) => {
  const { currentText, index } = TypeWriter(text);
  return (
    <code className="code">
      <span>className=</span>
      <span className="text-white">&quot;text-[#091f2c]&quot;</span>
      <span className="typewriter">
        <br />
        <code>{currentText}</code>
        {index.current === 37 && <>&#128161;</>}
      </span>
    </code>
  );
};
