export const MarketObjs = () => {
  const images = [
    {
      id: 1,
      code: "EURUSD",
      marketName: (
        <>
          Euro/ <br />
          US Dollar
        </>
      ),
      image: "/markets/eurusd.png",
    },
    {
      id: 2,
      code: "USDJPY",
      marketName: "US Dollar/ Japanese Yen",
      image: "/markets/usdjpy.png",
      fontPrev: "!text-[50px] !leading-[52px]",
      fontGen: "!text-[72px] !leading-[84.45px]",
    },
    {
      id: 3,
      code: "GBPUSD",
      marketName: (
        <>
          British Pound/
          <br />
          US Dollar
        </>
      ),
      image: "/markets/gbpusd.png",
      fontGen: "!text-[72px] !leading-[83.45px]",
    },
    {
      id: 4,
      code: "AUDUSD",
      marketName: (
        <>
          Australian Dollar/ <br />
          US Dollar
        </>
      ),
      image: "/markets/audusd.png",
      fontPrev: "!text-[54px] !leading-[54px]",
      fontGen: "!text-[74px] !leading-[85.77px]",
    },
    {
      id: 5,
      code: "USDCAD",
      marketName: (
        <>
          US Dollar/
          <br />
          Canadian Dollar
        </>
      ),
      image: "/markets/usdcad.png",
      fontPrev: "!text-[54px] !leading-[54px]",
      fontGen: "!text-[70px] !leading-[81.13px]",
    },
    {
      id: 6,
      code: "NZDUSD",
      marketName: (
        <>
          New Zealand Dollar/
          <br />
          US Dollar
        </>
      ),
      image: "/markets/nzdusd.png",
      fontPrev: "!text-[42px] !leading-[52px]",
      fontGen: "!text-[66px] !leading-[76.49px]",
    },
    {
      id: 7,
      code: "USDCHF",
      marketName: (
        <>
          US Dollar/
          <br />
          Swiss Franc
        </>
      ),
      image: "/markets/usdchf.png",
      fontPrev: "!text-[42px] !leading-[52px]",
      fontGen: "!text-[72px] !leading-[83.45px]",
    },
    {
      id: 8,
      code: "XAUUSD",
      marketName: <>Spot Gold </>,
      image: "/markets/Spot-gold.png",
    },
    {
      id: 9,
      code: "XAGUSD",
      marketName: <>Spot Silver</>,
      image: "/markets/spot-silver.png",
    },
    {
      id: 10,
      code: "APPL",
      marketName: <>Apple Inc.</>,
      image: "/markets/Apple.png",
    },
    {
      id: 11,
      code: "TSLA",
      marketName: <>Tesla, Inc.</>,
      image: "/markets/Tesla.png",
    },
    {
      id: 12,
      code: "AMZN",
      marketName: <>Amazon.com, Inc.</>,
      image: "/markets/amazon.png",
      fontPrev: "!text-[42px] !leading-[52px]",
      fontGen: "!text-[62px] !leading-[74.18px]",
    },
    {
      id: 13,
      code: "GOOGL",
      marketName: <>Alphabet, Inc.</>,
      image: "/markets/google.png",
    },
    {
      id: 14,
      code: "MSFT",
      marketName: <>Microsoft Corporation</>,
      image: "/markets/microsoft.png",
      fontPrev: "!text-[42px] !leading-[52px]",
      fontGen: "!text-[70px] !leading-[81.13px]",
    },
    {
      id: 15,
      code: "META",
      marketName: (
        <>
          Meta
          <br />
          Platforms Inc.
        </>
      ),
      image: "/markets/meta-platforms.png",
      fontPrev: "!text-[38px] !leading-[48px]",
      fontGen: "!text-[60px] !leading-[70px]",
    },
    {
      id: 16,
      code: "NFLX",
      marketName: <>Netflix, Inc.</>,
      image: "/markets/netflix.png",
      fontPrev: "!text-[42px] !leading-[52px]",
      fontGen: "!text-[72px] !leading-[82px]",
    },
    {
      id: 17,
      code: "BABA",
      marketName: (
        <>
          Alibaba Group
          <br />
          Holdings Ltd.
        </>
      ),
      image: "/markets/alibaba.png",
      fontPrev: "!text-[36px] !leading-[46px]",
      fontGen: "!text-[58px] !leading-[68px]",
    },
    {
      id: 18,
      code: "NIO",
      marketName: <>NIO, Inc.</>,
      image: "/markets/nio.png",
      fontPrev: "!text-[50px] !leading-[60px]",
      fontGen: "!text-[84px] !leading-[94px]",
    },
    {
      id: 19,
      code: "NVDA",
      marketName: <>NVIDIA Corp.</>,
      image: "/markets/nvidia.png",
    },
    {
      id: 20,
      code: "FTSE100",
      marketName: (
        <>
          UK 100
          <br />
          Cash
        </>
      ),
      image: "/markets/uk-100.png",
      fontPrev: "!text-[50px] !leading-[60px]",
      fontGen: "!text-[84px] !leading-[100px]",
    },
    {
      id: 21,
      code: "WS30",
      marketName: (
        <>
          Wall Street
          <br />
          30 Cash
        </>
      ),
      image: "/markets/us-30.png",
      fontPrev: "!text-[46px] !leading-[56px]",
      fontGen: "!text-[72px] !leading-[83px]",
    },
    {
      id: 22,
      code: "CAC40",
      marketName: (
        <>
          France 40
          <br />
          Cash
        </>
      ),
      image: "/markets/cac-40.png",
      fontPrev: "!text-[46px] !leading-[56px]",
      fontGen: "!text-[76px] !leading-[86px]",
    },
    {
      id: 23,
      code: "DAX40",
      marketName: (
        <>
          Germany
          <br />
          40 Cash
        </>
      ),
      image: "/markets/dax.png",
      fontPrev: "!text-[46px] !leading-[56px]",
      fontGen: "!text-[76px] !leading-[86px]",
    },
    {
      id: 24,
      code: "ASX200",
      marketName: (
        <>
          Australia
          <br />
          200 Cash
        </>
      ),
      image: "/markets/asx-200.png",
      fontPrev: "!text-[46px] !leading-[56px]",
      fontGen: "!text-[76px] !leading-[86px]",
    },
    {
      id: 25,
      code: "NDX100",
      marketName: (
        <>
          US Tech
          <br />
          100 Cash
        </>
      ),
      image: "/markets/nasdaq-100.png",
      fontPrev: "!text-[44px] !leading-[54px]",
      fontGen: "!text-[72px] !leading-[82px]",
    },
    {
      id: 26,
      code: "US500",
      marketName: (
        <>
          US 500
          <br />
          Cash
        </>
      ),
      image: "/markets/s-and-p-500.png",
      fontPrev: "!text-[48px] !leading-[58px]",
      fontGen: "!text-[80px] !leading-[90px]",
    },
    {
      id: 27,
      code: "ESP35",
      marketName: (
        <>
          Spain 35
          <br />
          Cash
        </>
      ),
      image: "/markets/ibex-35.png",
      fontPrev: "!text-[48px] !leading-[58px]",
      fontGen: "!text-[80px] !leading-[90px]",
    },
    {
      id: 28,
      code: "STOXX50",
      marketName: (
        <>
          Europe 50
          <br />
          Cash
        </>
      ),
      image: "/markets/euro-stoxx-50.png",
      fontPrev: "!text-[48px] !leading-[58px]",
      fontGen: "!text-[80px] !leading-[90px]",
    },

    {
      id: 29,
      code: "DIS.NYSE",
      marketName: <>The Walt Disney Company</>,
      image: "/markets/walt-disney.png",
      fontPrev: "!text-[48px] !leading-[58px]",
      fontGen: "!text-[80px] !leading-[90px]",
    },
    {
      id: 30,
      code: "ADBE.NAS",
      marketName: <>Adobe Inc.</>,
      image: "/markets/adobe.png",
      fontPrev: "!text-[48px] !leading-[58px]",
      fontGen: "!text-[80px] !leading-[90px]",
    },
    {
      id: 31,
      code: "RCL.NYSE",
      marketName: <>Royal Caribbean Cruises Ltd.</>,
      image: "/markets/royal-caribbean-cruises.png",
      fontPrev: "!text-[48px] !leading-[58px]",
      fontGen: "!text-[80px] !leading-[90px]",
    },
    {
      id: 32,
      code: "CCL.NYSE",
      marketName: <>Carnival Corp.</>,
      image: "/markets/carnival.png",
      fontPrev: "!text-[48px] !leading-[58px]",
      fontGen: "!text-[80px] !leading-[90px]",
    },
    {
      id: 33,
      code: "INTC.NAS",
      marketName: <>Intel Corp.</>,
      image: "/markets/intel.png",
      fontPrev: "!text-[48px] !leading-[58px]",
      fontGen: "!text-[80px] !leading-[90px]",
    },
    {
      id: 34,
      code: "BA.NYSE",
      marketName: <>Boeing Co.</>,
      image: "/markets/boeing.png",
      fontPrev: "!text-[48px] !leading-[58px]",
      fontGen: "!text-[80px] !leading-[90px]",
    },
    {
      id: 35,
      code: "V.NYSE",
      marketName: <>Visa Inc.</>,
      image: "/markets/visa.png",
      fontPrev: "!text-[48px] !leading-[58px]",
      fontGen: "!text-[80px] !leading-[90px]",
    },
    {
      id: 36,
      code: "CRM.NYSE",
      marketName: <>Salesforce Inc.</>,
      image: "/markets/salesforce.png",
      fontPrev: "!text-[48px] !leading-[58px]",
      fontGen: "!text-[80px] !leading-[90px]",
    },
    {
      id: 37,
      code: "CSCO.NAS",
      marketName: <>Cisco Systems Inc.</>,
      image: "/markets/cisco.png",
      fontPrev: "!text-[48px] !leading-[58px]",
      fontGen: "!text-[80px] !leading-[90px]",
    },
    {
      id: 38,
      code: "CAT.NYSE",
      marketName: <>Caterpillar Inc.</>,
      image: "/markets/caterpillar.png",
      fontPrev: "!text-[48px] !leading-[58px]",
      fontGen: "!text-[80px] !leading-[90px]",
    },
    {
      id: 39,
      code: "WMT.NYSE",
      marketName: <>Walmart Inc.</>,
      image: "/markets/walmart.png",
      fontPrev: "!text-[48px] !leading-[58px]",
      fontGen: "!text-[80px] !leading-[90px]",
    },
    {
      id: 40,
      code: "GE.NYSE",
      marketName: <>General Electric Co.</>,
      image: "/markets/general-electric.png",
      fontPrev: "!text-[48px] !leading-[58px]",
      fontGen: "!text-[80px] !leading-[90px]",
    },
    {
      id: 41,
      code: "USOUSD",
      marketName: <>WTI Crude</>,
      image: "/markets/crude-oil2.png",
      fontPrev: "!text-[48px] !leading-[58px]",
      fontGen: "!text-[80px] !leading-[90px]",
    },
    //NEW
    {
      id: 42,
      code: "SYR",
      marketName: <>Syrah Resources Ltd</>,
      image: "/markets/syrah-resources-ltd1.png",
      fontPrev: "!text-[48px] !leading-[58px]",
      fontGen: "!text-[80px] !leading-[90px]",
    },
    {
      id: 43,
      code: "IVV",
      marketName: <>iShares S&P 500 ETF</>,
      image: "/markets/ishares-big-1.png",
      fontPrev: "!text-[48px] !leading-[58px]",
      fontGen: "!text-[80px] !leading-[90px]",
    },
    {
      id: 44,
      code: "CBA",
      marketName: <>Commonwealth Bank of Australia</>,
      image: "/markets/commonwealth-bank-big1.png",
      fontPrev: "!text-[36px] !leading-[58px]",
      fontGen: "!text-[58px] !leading-[90px]",
    },
    {
      id: 45,
      code: "AX1",
      marketName: <>Accent Group Ltd</>,
      image: "/markets/accent-group.png",
      fontPrev: "!text-[48px] !leading-[58px]",
      fontGen: "!text-[80px] !leading-[90px]",
    },
    {
      id: 46,
      code: "CTD",
      marketName: <>Corporate Travel Management Ltd</>,
      image: "/markets/corporate-travel-management--big 1.png",
      fontPrev: "!text-[42px] !leading-[58px]",
      fontGen: "!text-[68px] !leading-[90px]",
    },
    {
      id: 47,
      code: "AGL",
      marketName: <>AGL Energy Limited</>,
      image: "/markets/agl-energy--big 1.png",
      fontPrev: "!text-[48px] !leading-[58px]",
      fontGen: "!text-[80px] !leading-[90px]",
    },
    {
      id: 48,
      code: "RRL",
      marketName: <>Regis Resources Ltd</>,
      image: "/markets/regis-resources--big 1.png",
      fontPrev: "!text-[48px] !leading-[58px]",
      fontGen: "!text-[80px] !leading-[90px]",
    },
    {
      id: 49,
      code: "NVX",
      marketName: <>Novonix Ltd</>,
      image: "/markets/novonix--big 1.png",
      fontPrev: "!text-[48px] !leading-[58px]",
      fontGen: "!text-[80px] !leading-[90px]",
    },
    {
      id: 50,
      code: "29M",
      marketName: <>29Metals Ltd</>,
      image: "/markets/29-metals.png",
      fontPrev: "!text-[48px] !leading-[58px]",
      fontGen: "!text-[80px] !leading-[90px]",
    },
    {
      id: 51,
      code: "WHC",
      marketName: <>Whitehaven Coal Ltd</>,
      image: "/markets/nio2.png",
      fontPrev: "!text-[48px] !leading-[58px]",
      fontGen: "!text-[80px] !leading-[90px]",
    },
  ];

  const forexOptions = [
    { name: "EURUSD", value: "EURUSD" },
    { name: "USDJPY", value: "USDJPY" },
    { name: "GBPUSD", value: "GBPUSD" },
    { name: "AUDUSD", value: "AUDUSD" },
    { name: "USDCAD", value: "USDCAD" },
    { name: "NZDUSD", value: "NZDUSD" },
    { name: "USDCHF", value: "USDCHF" },
  ];

  const metalOptions = [
    { name: "XAUUSD", value: "XAUUSD" },
    { name: "XAGUSD", value: "XAGUSD" },
    { name: "USOUSD", value: "USOUSD" },
  ];

  const indicesOptions = [
    { name: "FTSE100", value: "FTSE100" },
    { name: "WS30", value: "WS30" },
    { name: "CAC40", value: "CAC40" },
    { name: "DAX40", value: "DAX40" },
    { name: "ASX200", value: "ASX200" },
    { name: "NDX100", value: "NDX100" },
    { name: "US500", value: "US500" },
    { name: "ESP35", value: "ESP35" },
    { name: "STOXX50", value: "STOXX50" },
  ];

  const sharesOptions = [
    { name: "APPL", value: "APPL" },
    { name: "TSLA", value: "TSLA" },
    { name: "GOOGL", value: "GOOGL" },
    { name: "AMZN", value: "AMZN" },
    { name: "MSFT", value: "MSFT" },
    { name: "META", value: "META" },
    { name: "NFLX", value: "NFLX" },
    { name: "BABA", value: "BABA" },
    { name: "NIO", value: "NIO" },
    { name: "NVDA", value: "NVDA" },
    { name: "DIS.NYSE", value: "DIS.NYSE" },
    { name: "ADBE.NAS", value: "ADBE.NAS" },
    { name: "RCL.NYSE", value: "RCL.NYSE" },
    { name: "CCL.NYSE", value: "CCL.NYSE" },
    { name: "INTC.NAS", value: "INTC.NAS" },
    { name: "BA.NYSE", value: "BA.NYSE" },
    { name: "V.NYSE", value: "V.NYSE" },
    { name: "CRM.NYSE", value: "CRM.NYSE" },
    { name: "CSCO.NAS", value: "CSCO.NAS" },
    { name: "CAT.NYSE", value: "CAT.NYSE" },
    { name: "WMT.NYSE", value: "WMT.NYSE" },
    { name: "GE.NYSE", value: "GE.NYSE" },
  ];

  const auSharesOptions = [
    { name: "SYR", value: "SYR" },
    { name: "IVV", value: "IVV" },
    { name: "CBA", value: "CBA" },
    { name: "AX1", value: "AX1" },
    { name: "CTD", value: "CTD" },
    { name: "AGL", value: "AGL" },
    { name: "RRL", value: "RRL" },
    { name: "NVX", value: "NVX" },
    { name: "29M", value: "29M" },
    { name: "WHC", value: "WHC" },
  ];

  return {
    images,
    forexOptions,
    metalOptions,
    indicesOptions,
    sharesOptions,
    auSharesOptions,
  };
};
