import Joyride, { STATUS } from "react-joyride";

export default function Walkthrough(props) {
  const state = {
    run: false,
    steps: props.walkthSteps,
  };

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status) && status !== STATUS.RUNNING) {
      localStorage.setItem(props.tutorialStatus, status);
      // console.log(status);
    }
  };

  const styling = {
    tooltip: {
      padding: 0,
    },
    tooltipFooter: {
      alignItems: "center",
      display: "flex",
      justifyContent: "flex-end",
      marginTop: 25,
      borderTop: "1px solid #E2E5EC",
      padding: 10,
    },
    buttonNext: {
      background: "#0bab47",
      fontSize: 12,
      fontWeight: 700,
      fontFamily: "Roboto",
    },
    buttonSkip: {
      color: "#0bab47",
      fontSize: 12,
      fontWeight: 400,
      fontFamily: "Roboto",
    },
    options: {
      arrowColor: "#FFFFFF",
      backgroundColor: "#FFFFFF",
      primaryColor: "#0bab47",
      textColor: "#091F2C",
      zIndex: 1000,
    },
    tooltipContainer: {
      textAlign: "left",
      padding: 10,
    },
    tooltipTitle: {
      fontSize: 16,
      fontFamily: "Roboto",
      fontWeight: 700,
      marginTop: 10,
      marginLeft: 30,
      marginRight: 30,
    },
    tooltipContent: {
      fontSize: 14,
      fontFamily: "Roboto",
      fontWeight: 400,
      padding: 0,
      marginTop: 10,
      marginLeft: 30,
      marginRight: 30,
    },
    overlay: {
      background: "rgba(236, 240, 252, 0.6)",
      opacity: 0.6,
    },
    spotlight: {
      backgroundColor: "rgba(236, 240, 252, 0.6)",
      display: "hidden",
    },
  };

  return (
    <div className="app">
      <Joyride
        floaterProps={{ placement: "right" }}
        steps={state.steps}
        callback={props.run ? handleJoyrideCallback : ""}
        showProgress={false}
        showSkipButton
        hideCloseButton
        hideBackButton={true}
        run={props.run}
        continuous
        styles={styling}
      />
      {props.children}
    </div>
  );
}
