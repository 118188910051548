import React, { createContext, useEffect, useState } from "react";

export const ResponseContext = createContext({
  response: {},
  setResponse: () => {},
});

function ResponseProvider({ children }) {
  const [response, setResponse] = useState(null);

  useEffect(() => {
    setTimeout(() => {
      setResponse(null);
    }, 3000);
  }, [response]);

  return (
    <ResponseContext.Provider value={{ response, setResponse }}>
      {children}
    </ResponseContext.Provider>
  );
}

export default ResponseProvider;
