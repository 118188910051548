import React, { createContext, useContext, useEffect, useState } from "react";
import { GetUploads } from "../services/firebaseActions/uploaderActions";
import { StatusContext } from "./statusProvider";

export const UploadContext = createContext({
  response: {},
  setResponse: () => {},
});

function UploadProvider({ children }) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { updateAPI, handleApi } = useContext(StatusContext);

  useEffect(() => {
    setIsLoading(true);
    GetUploads().then((res) => {
      if (res.status === 200) {
        setIsLoading(false);
        setData([res.data]);
        handleApi(false);
      }
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateAPI]);
  return (
    <UploadContext.Provider value={{ data, isLoading }}>
      {children}
    </UploadContext.Provider>
  );
}

export default UploadProvider;
