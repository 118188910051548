/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

export function usePaginate(data, deps) {
  let index = 10;
  const [showingItems, setShowingItems] = useState(index);
  const [dataLength, setDataLength] = useState(0);

  useEffect(() => {
    setShowingItems(index);
  }, [deps]);

  useEffect(() => {
    if (data) {
      setDataLength(Object.keys(data).length);
    } else {
      setDataLength(0);
    }
  }, [data]);

  const handlePage = () => {
    if (data) {
      if (dataLength - showingItems >= index) {
        setShowingItems(showingItems + index);
      } else {
        setShowingItems(showingItems + (dataLength - showingItems));
      }
    }
  };

  const PaginateBtn = () => {
    if (showingItems !== dataLength && dataLength > index) {
      return (
        <button
          className="text-sm font-medium show-more mt-5 relative focus:outline-none flex items-center pointer-events-auto"
          onClick={handlePage}
        >
          show more
        </button>
      );
    }
  };

  return {
    showingItems,
    PaginateBtn,
  };
}
