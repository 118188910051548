//File names
export const DIVIDEND_FEED = "dividend_feeds.csv";
export const GENERAL = "general";
export const TRADING_HOURS = "trading_hours.csv";
export const LEGAL_DOCUMENTS = "legal_docs";

//Categories
export const C_LEGAL_DOCUMENTS = "legal_documents";
export const C_GENERAL = "general";
export const C_DIVIDEND_FEED = "dividend_feed";
export const C_TRADING_HOURS = "trading_hours";
