import { Badge, Button, Spinner } from "flowbite-react";
import { useState, useRef } from "react";
import { CustomModal } from "../../layouts/customModal";
import { useNavigate } from "react-router-dom";
import LoginAuth from "../../services/firebaseActions/loginAuth";
import { GoogleIcn } from "../../layouts/icons";

export const LoginModal = (props) => {
  let navigate = useNavigate();
  const modalRef = useRef(null);
  const [status, setStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleLogin = () => {
    LoginAuth(navigate, window.location.pathname, setStatus, setIsLoading);
  };

  const handleClose = () => {
    modalRef.current();
    if (props.navigate !== "") {
      navigate(props.navigate, { replace: true });
    }
  };

  return (
    <CustomModal
      btnText={props.btnText}
      googleBtn={props.googleBtn}
      isLoading={isLoading}
      modalRef={modalRef}
      isIndex={window.location.pathname === "/"}
    >
      {status &&
        (status === "auth/internal-error" ? (
          <Badge color="failure">Unauthorized Email</Badge>
        ) : (
          <Badge color="failure">{status}</Badge>
        ))}

      <div className="space-y-6 mt-2 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8">
        <h3 className="text-xl font-medium text-gray-900 dark:text-white">
          Sign in Required
        </h3>

        <div className="w-full google-btn">
          <Button onClick={handleLogin} disabled={isLoading ? true : false}>
            {isLoading ? (
              <span className="inline-flex items-center justify-center">
                <Spinner aria-label="spinner" />
                <span className="pl-3">Loading...</span>
              </span>
            ) : (
              <span className="inline-flex items-center justify-center">
                <GoogleIcn />
                Continue with google
              </span>
            )}
          </Button>
        </div>
        <div className="text-sm font-medium text-gray-500 dark:text-gray-300">
          To access the tool you need to sign in with your GO Markets Gmail
          account.
        </div>
        <button
          className={`text-sm font-semibold  ${
            isLoading ? "text-gray-400" : ""
          }`}
          onClick={handleClose}
          disabled={isLoading}
        >
          Cancel
        </button>
      </div>
    </CustomModal>
  );
};
