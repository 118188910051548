import { SiteFooter } from "./footer";
import { Header } from "./header";

export const MainLayout = ({ children }) => {
  return (
    <>
      <Header />
      {children}
      <SiteFooter />
    </>
  );
};
