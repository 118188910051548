import { useState } from "react";

const options = [
  { id: 1, name: "English", value: "en" },
  { id: 2, name: "Thai", value: "th" },
  { id: 3, name: "Simplified Chinese", value: "zh-CN" },
  { id: 4, name: "Traditional Chinese", value: "zh-TW" },
  { id: 5, name: "Vietnamese", value: "vi" },
  { id: 6, name: "Spanish", value: "es" },
  { id: 7, name: "Portuguese", value: "pt" },
  { id: 8, name: "Indonesian", value: "id" },
];

export const LanguagePanel = (props) => {
  const [checked, setChecked] = useState(false);

  return (
    <div className="st-2 image-details">
      <div className="input-group inline-block w-full">
        <div className="mb-7">
          <input
            id="checked-checkbox"
            type="checkbox"
            className="cursor-pointer inline-block float-right w-4 h-4 mt-1 text-gogreen bg-white-100 border-border focus:ring-gogreen dark:focus:ring-gogreen dark:ring-offset-gogreen focus:ring-2 dark:bg-gogreen dark:border-gogreen"
            onChange={() => {
              setChecked(!checked);
            }}
          />
          <h3 className="font-display text-lg font-bold text-sub">Translate</h3>

          <small className="text-primary">
            This can be used to get suggested translations.
          </small>
        </div>
      </div>
      <div className={`input-group ${!checked && "w-full space-y-2 z-5 blur"}`}>
        <div className="mb-4">
          <div className="mb-1">
            <label className="font-body text-sm font-normal text-sub w-full">
              Language list
            </label>
          </div>

          <select
            defaultValue={"en"}
            onChange={props.handleLanguage}
            className={`w-2/5 border-border cursor-pointer text-sm focus:border-gogreen focus:ring-gogreen ${
              checked ? "cursor-pointer" : "cursor-default"
            }`}
            id="demo-simple-select"
            name="lang"
            label="Set Font Size"
            disabled={checked ? false : true}
          >
            <option disabled={true} value="DEFAULT">
              Language list
            </option>
            {options.map((item, index) => {
              return (
                <option key={index} value={item.value}>
                  {item.name}
                </option>
              );
            })}
          </select>
          <div>
            <small className="text-slate-500">
              The translations are English to your selected language.
            </small>
          </div>
        </div>
        <div className="mt-1 mb-2">
          <label className="font-body text-sm font-normal text-sub w-full">
            Suggested Translation
          </label>
          {props.topText !== undefined && (
            <input
              type="text"
              id="disabled-input-2"
              aria-label="disabled input 2"
              className="bg-gray-100 border border-gray-300 text-gray-900 text-sm overflow-x-auto w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-400"
              value={
                props.topText ? props.translateTop : props.topTextPlaceHolder
              }
              disabled
              readOnly
            />
          )}
        </div>
        {props.bottomText !== undefined && (
          <div className="mt-1">
            <input
              type="text"
              id="disabled-input-2"
              aria-label="disabled input 2"
              className="bg-gray-100 border border-gray-300 text-gray-900 text-sm overflow-x-auto w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-400"
              value={
                props.bottomText
                  ? props.translateBottom
                  : props.btmTextPlaceHolder
              }
              disabled
              readOnly
            />
          </div>
        )}
      </div>
    </div>
  );
};
