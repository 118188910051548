export const images = [
  {
    id: 1,
    name: "GFA",
    image: "/global/1200x628-GFA-campaign-hero.jpg",
  },
  {
    id: 2,
    name: "Price tickers",
    image: "/global/1200x628-booster.jpg",
  },
  {
    id: 3,
    name: "City",
    image: "/global/1200x628-rebate.jpg",
  },
  {
    id: 4,
    name: "Trends",
    image: "/global/1200x628-IT-awards.png",
  },
  {
    id: 5,
    name: "Holiday",
    image: "/global/1200x628-Holiday-prize.png",
  },
  {
    id: 6,
    name: "GO for Gold",
    image: "/global/1200x628-go-for-gold.jpg",
  },
  {
    id: 7,
    name: "Safety Net",
    image: "/global/1200x628-protection.jpg",
  },
  {
    id: 8,
    name: "Low FX",
    image: "/global/1200x628-low-fx.jpg",
  },
  {
    id: 9,
    name: "AMZN",
    image: "/global/1200x628-AMZN-base.jpg",
  },
  {
    id: 10,
    name: "GOOGLE",
    image: "/global/1200x628-GOOG-base.jpg",
  },
  {
    id: 11,
    name: "MSFT",
    image: "/global/1200x628-MSFT-base.jpg",
  },
  {
    id: 12,
    name: "TSLA",
    image: "/global/1200x628-TSLA-base.jpg",
  },
  {
    id: 13,
    name: "us-shares",
    image: "/global/1200x628-us-shares-base.jpg",
  },
  {
    id: 14,
    name: "Trade Gold",
    image: "/global/1200x628-trade-gold-base.jpg",
  },
  {
    id: 15,
    name: "Trade Silver",
    image: "/global/1200x628-trade-Silver-base.jpg",
  },
  {
    id: 16,
    name: "Trade Metals",
    image: "/global/1200x628-trade-metals-base.jpg",
  },
  {
    id: 17,
    name: "5X Winner",
    image: "/global/1200x628-IT-5times-base.jpg",
  },
  {
    id: 18,
    name: "#1",
    image: "/global/1200x628-IT-rated1-base.jpg",
  },
  {
    id: 19,
    name: "Badges",
    image: "/global/1200x628-IT-Badges-base.jpg",
  },
  {
    id: 20,
    name: "Default",
    image: "/global/1200x628-default-base.jpg",
  },
  {
    id: 21,
    name: "NVDA",
    image: "/global/NVDA.jpg",
  },
];
export const images_overlay2 = [
  {
    id: 1,
    name: "Price tickers",
    image: "/global/1080x1080-gfa-campaigngfa-campaign.jpg",
  },
  {
    id: 2,
    name: "Default",
    image: "/global/1080x1080-booster.jpg",
  },
  {
    id: 3,
    name: "City",
    image: "/global/1080x1080-rebate.jpg",
  },
  {
    id: 4,
    name: "Trends",
    image: "/global/1080x1080-IT-awards.png",
  },
  {
    id: 5,
    name: "Holiday",
    image: "/global/1080x1080-Holiday-prize.png",
  },
  {
    id: 6,
    name: "GO for Gold",
    image: "/global/1080x1080-go-for-gold.jpg",
  },
  {
    id: 7,
    name: "Safety Net",
    image: "/global/1080x1080-protection.jpg",
  },
  {
    id: 8,
    name: "Low FX",
    image: "/global/1080x1080-low-fx.jpg",
  },
  {
    id: 9,
    name: "AMZN",
    image: "/global/1080x1080-AMZN-base.jpg",
  },
  {
    id: 10,
    name: "GOOGLE",
    image: "/global/1080x1080-GOOG-base.jpg",
  },
  {
    id: 11,
    name: "MSFT",
    image: "/global/1080x1080-MSFT-base.jpg",
  },
  {
    id: 12,
    name: "TSLA",
    image: "/global/1080x1080-TSLA-base.jpg",
  },
  {
    id: 13,
    name: "us-share",
    image: "/global/1080x1080-us-shares-base.jpg",
  },
  {
    id: 14,
    name: "Trade Gold",
    image: "/global/1080x1080-trade-gold-base.jpg",
  },
  {
    id: 15,
    name: "Trade Silver",
    image: "/global/1080x1080-trade-Silver-base.jpg",
  },
  {
    id: 16,
    name: "Trade Metals",
    image: "/global/1080x1080-trade-metals-base.jpg",
  },
  {
    id: 17,
    name: "5X Winner",
    image: "/global/1080x1080-IT-5times-base.jpg",
  },
  {
    id: 18,
    name: "#1",
    image: "/global/1080x1080-IT-rated1-base.jpg",
  },
  {
    id: 19,
    name: "Badges",
    image: "/global/1080x1080-IT-Badges-base.jpg",
  },
  {
    id: 20,
    name: "Default",
    image: "/global/1080x1080-default-base.jpg",
  },
  {
    id: 21,
    name: "NVDA",
    image: "/global/NVDA-1080.jpg",
  },
];
