export const youtubeimages = [
  {
    id: 1,
    name: "Default",
    image: "/660x720-trading.jpg",
  },
  {
    id: 2,
    name: "Trading",
    image: "/660x720-trading-fx.jpg",
  },
  {
    id: 3,
    name: "Buy Sell",
    image: "/660x720-buy-sell.jpg",
  },
  {
    id: 4,
    name: "Gold",
    image: "/660x720-gold.jpg",
  },
  {
    id: 5,
    name: "MT4",
    image: "/660x720-mt4.jpg",
  },
  {
    id: 6,
    name: "Lucas",
    image: "/660x720-swatch-lucas.jpg",
  },
  {
    id: 7,
    name: "Adam",
    image: "/YT-chart-day-adam-660x720.png",
  },
];
