import { useContext, useRef } from "react";
import { CustomModal } from "../../layouts/customModal";
import { CircleCaution, Trash } from "../../layouts/icons";
import { Delete } from "../../services/firebaseActions/uploaderActions";
import { Dialog } from "@headlessui/react";
import { Button } from "flowbite-react";
import { StatusContext } from "../../context/statusProvider";

export const DeleteModal = (props) => {
  const modalRef = useRef(null);
  const openRef = useRef(null);
  const { handleApi } = useContext(StatusContext);

  function handleDelete() {
    Delete(props.value, props.category, handleApi);
    modalRef.current();
  }

  const handleClose = () => {
    modalRef.current();
  };

  return (
    <CustomModal
      btnText={"Delete"}
      modalRef={modalRef}
      openRef={openRef}
      customBtn={
        <div id="modalBtn" className="mt-4">
          <Button
            onClick={() => {
              openRef.current();
            }}
          >
            <Trash />
            {"Delete"}
          </Button>
        </div>
      }
    >
      <Dialog.Title
        as="h3"
        className="text-lg font-medium leading-6 text-gray-900 inline-flex"
      >
        <strong className="flex align-middle">
          <CircleCaution />
          Delete File
        </strong>
      </Dialog.Title>

      <div className="mt-2">
        <p className="text-sm text-gray-500">
          <span>
            Are you sure you want to delete{" "}
            <strong className="break-words">{props.value.file_name}</strong> ?
          </span>
        </p>
      </div>

      <div className="mt-4 inline-flex gap-3">
        <button className="text-sm font-semibold" onClick={handleClose}>
          Cancel
        </button>
        <button
          type="button"
          className="inline-flex justify-center rounded-md border border-transparent  px-4 py-2 text-sm font-medium  focus:outline-none text-red-600 bg-red-100"
          onClick={handleDelete}
        >
          Confirm
        </button>
      </div>
    </CustomModal>
  );
};
