export const country = [
  {
    id: 1,
    name: "Default",
    image: "/eco/Property 1=default.png",
  },
  {
    id: 2,
    name: "Australia",
    image: "/eco/Property 1=au.png",
  },
  {
    id: 3,
    name: "Bulgaria",
    image: "/eco/Property 1=BG.png",
  },
  {
    id: 4,
    name: "Canada",
    image: "/eco/Property 1=canada.png",
  },
  {
    id: 5,
    name: "China",
    image: "/eco/Property 1=China.png",
  },
  {
    id: 5,
    name: "Denmark",
    image: "/eco/Property 1=DK.png",
  },
  {
    id: 6,
    name: "Euro",
    image: "/eco/Property 1=euro.png",
  },
  {
    id: 7,
    name: "France",
    image: "/eco/Property 1=France.png",
  },
  {
    id: 8,
    name: "Germany",
    image: "/eco/Property 1=Germany.png",
  },
  {
    id: 9,
    name: "Hong Kong",
    image: "/eco/Property 1=HK.png",
  },
  {
    id: 10,
    name: "Hungry",
    image: "/eco/Property 1=HU.png",
  },
  {
    id: 11,
    name: "Italy",
    image: "/eco/Property 1=Italy.png",
  },
  {
    id: 12,
    name: "Japan",
    image: "/eco/Property 1=Japan.png",
  },
  {
    id: 13,
    name: "Mexico",
    image: "/eco/Property 1=MEX.png",
  },
  {
    id: 14,
    name: "Malaysia",
    image: "/eco/Property 1=MY.png",
  },
  {
    id: 15,
    name: "Norway",
    image: "/eco/Property 1=NO.png",
  },
  {
    id: 16,
    name: "New Zealand",
    image: "/eco/Property 1=NZ.png",
  },
  {
    id: 17,
    name: "Poland",
    image: "/eco/Property 1=Poland.png",
  },
  {
    id: 18,
    name: "Russia",
    image: "/eco/Property 1=RUS.png",
  },
  {
    id: 19,
    name: "Sweden",
    image: "/eco/Property 1=SE.png",
  },
  {
    id: 20,
    name: "Singapore",
    image: "/eco/Property 1=Singapore.png",
  },
  {
    id: 21,
    name: "Spain",
    image: "/eco/Property 1=Spain.png",
  },
  {
    id: 22,
    name: "Switzerland",
    image: "/eco/Property 1=Switzerland.png",
  },
  {
    id: 23,
    name: "UAE",
    image: "/eco/Property 1=UAE.png",
  },
  {
    id: 24,
    name: "UK",
    image: "/eco/Property 1=uk-flag.png",
  },
  {
    id: 25,
    name: "USA",
    image: "/eco/Property 1=usa.png",
  },
  {
    id: 26,
    name: "South Africa",
    image: "/eco/Property 1=ZA.png",
  },
];

export const gmt = [
  { id: 1, value: "GMT+2" },
  { id: 2, value: "GMT+7" },
  { id: 3, value: "GMT+10" },
];
