import {
  C_DIVIDEND_FEED,
  C_TRADING_HOURS,
  DIVIDEND_FEED,
  TRADING_HOURS,
} from "../../constants/types";

export const RenameFile = (bits, name, options) => {
  try {
    return new File(bits, name, options);
  } catch (e) {
    var myBlob = new Blob(bits, options || {});
    myBlob.lastModified = new Date();
    myBlob.name = name;
    return myBlob;
  }
};

export const FileFormat = (file, category) => {
  //dividends
  if (category === C_DIVIDEND_FEED) {
    const test = RenameFile([file], DIVIDEND_FEED, {
      type: ".csv, text/csv",
    });

    return test;
  }
  //trading hours
  else if (category === C_TRADING_HOURS) {
    const test = RenameFile([file], TRADING_HOURS, {
      type: ".csv, text/csv",
    });

    return test;
  }
  //other categories
  else {
    var fileNameFormat = /\s/g.test(file.name);
    if (fileNameFormat) {
      const test = RenameFile([file], file.name.replace(/\s/g, ""), {
        type: "plain/text",
      });
      return test;
    } else {
      return file;
    }
  }
};
