import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ImgGenerator from "../components/image-generator/ImgGenerator";
import { IMAGE_GENERATOR } from "../routes/routes";
import { MainLayout } from "../layouts/mainLayout";

function Main(props) {
  const navigate = useNavigate();
  const [formType, setFormType] = useState("Analysis-Articles");

  const child_data = (data) => {
    if (data) {
      setFormType(data);
    }
  };

  useEffect(() => {
    navigate(`/${IMAGE_GENERATOR}/${formType}`);
  }, [navigate, formType]);

  return (
    <MainLayout>
      <div className="bg-bg pt-12 pb-16 mb-12 px-2 sm:px-4">
        <div className="container mx-auto px-2 lg:px-12">
          <div className="grid grid-cols-12 sm:px-4">
            <div className="col-span-6 my-auto">
              <h1 className="font-display text-5xl leading-tight font-extrabold text-main">
                Social Media Image Creator
              </h1>
              <p className="font-body text-lg font-normal text-sub mt-6">
                Create and customise your image by using the fields below.
              </p>
            </div>
            <div className="col-span-6">
              <div className="flex justify-center">
                <img
                  src="/social-header-imgx2@2x.jpg"
                  className="img-header"
                  alt=""
                  width="360"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <ImgGenerator
        locationState={props.locationState}
        userData={child_data}
        formRoute={formType}
      />
    </MainLayout>
  );
}

export default Main;
