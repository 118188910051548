import { useState } from "react";

export function useHandleChange(initialValue) {
  const [params, setParams] = useState(initialValue);

  const handleChange = (e) => {
    let targetValue = e.target.value;
    setParams({ ...params, [e.target.name]: targetValue });
    if (
      e.target.name === "randomImg" ||
      e.target.name === "webinarImg" ||
      e.target.name === "secondaryImg"
    ) {
      const { files } = e.target;
      const uploadFile = URL.createObjectURL(files[0]);
      setParams({ ...params, [e.target.name]: uploadFile });
    }
  };

  //Reset Form
  const handleFormReset = () => {
    setParams(() => initialValue);

    setParams({ ...initialValue, form: params.form });
    if (params.form === "Webinar-Seminars") {
      setParams({ ...initialValue, webinaroverlay: params.webinaroverlay });
    } else if (params.form === "Global-Campaigns") {
      setParams({ ...initialValue, globaloverlay: params.globaloverlay });
    } else if (params.form === "Kooyong-Event") {
      setParams({ ...initialValue, kooyongoverlay: params.kooyongoverlay });
    } else if (params.form === "Analysis-Articles") {
      setParams({ ...initialValue, overlay: params.overlay });
    }
  };

  return {
    params,
    setParams,
    handleFieldChange: handleChange,
    handleFormReset,
  };
}
