import React, { useEffect, useState } from "react";
import { PriceDown, PriceUp } from "../../../layouts/icons";
import { MarketObjs } from "../../../utilities/imgGenerator/marketObjs";

function MarketMovers(props) {
  const marketType = props.data.marketType;
  const [selected, setSelected] = useState({});

  useEffect(() => {
    let obj = MarketObjs().images.find((o) => o.code === props.data.market);
    setSelected(obj);
  }, [props.data.market]);

  return (
    <div className="marketMovers">
      <div className="grid grid-cols-12 gap-8 sm:px-4">
        <div className="col-span-12 sm:col-span-12 lg:col-span-5">
          <p className="mb-6">
            Create and customise your image by using the fields below webinars.
          </p>

          <form>
            <div>
              <h3 className="font-display text-lg font-bold text-sub mb-7">
                Image Settings
              </h3>

              <div className="image-details">
                <div className="flex justify-left">
                  <label
                    id="demo-radio-buttons-group-label"
                    className="font-body text-sm font-normal text-sub mr-9"
                  >
                    Image size
                  </label>
                  <div className="form-check form-check-inline mr-5">
                    <input
                      className="form-check-input form-check-input rounded-full h-4 w-4 text-gogreen bg-gray-100 border-gray-300 focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 float-left mr-2 mt-1 align-top cursor-pointer"
                      type="radio"
                      name="marketsoverlay"
                      id="inlineRadio1"
                      value="overlay1"
                      onChange={props.handleChange}
                      defaultChecked
                    />
                    <label
                      className="form-check-label inline-block font-body text-sm font-normal text-sub"
                      htmlFor="inlineRadio20"
                    >
                      1080x1080
                    </label>
                  </div>
                  <div className="form-check form-check-inline mr-5">
                    <input
                      className="form-check-input form-check-input rounded-full h-4 w-4 text-gogreen bg-gray-100 border-gray-300 focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 float-left mr-2 mt-1 align-top cursor-pointer"
                      type="radio"
                      name="marketsoverlay"
                      id="inlineRadio2"
                      value="overlay2"
                      onChange={props.handleChange}
                    />
                    <label
                      className="form-check-label inline-block font-body text-sm font-normal text-sub"
                      htmlFor="inlineRadio20"
                    >
                      1080x1920
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <h3 className="font-display text-lg font-bold text-sub mb-7">
                Image Details
              </h3>
              <h3 className="font-display text-base font-bold text-sub mb-1">
                Market Type
              </h3>
              <p className="mb-6">
                Select the Market category and then enter the price details
              </p>
              <ul className="grid max-w-[480px] gap-2 md:grid-cols-4">
                <li>
                  <input
                    type="radio"
                    id="forex"
                    name="marketType"
                    value="forex"
                    className="hidden peer"
                    onChange={props.handleChange}
                    defaultChecked
                    required
                  />
                  <label
                    htmlFor="forex"
                    className="inline-flex items-center w-[114px] p-[6px] cursor-pointer text-[#95A7B8] bg-white border border-[#95A7B8] rounded-[60px]  hover:bg-primary hover:text-white peer-checked:border-primary peer-checked:text-white peer-checked:bg-primary"
                  >
                    <div className="w-full text-center font-[500] text-[14px]">
                      Forex
                    </div>
                  </label>
                </li>
                <li>
                  <input
                    type="radio"
                    id="metals"
                    name="marketType"
                    value="metals"
                    onChange={props.handleChange}
                    className="hidden peer"
                  />
                  <label
                    htmlFor="metals"
                    className="inline-flex items-center w-[114px] p-[6px] cursor-pointer text-[#95A7B8] bg-white border border-[#95A7B8] rounded-[60px]  hover:bg-primary hover:text-white peer-checked:border-primary peer-checked:text-white peer-checked:bg-primary"
                  >
                    <div className="w-full text-center font-[500] text-[14px]">
                      Commodities
                    </div>
                  </label>
                </li>
                <li>
                  <input
                    type="radio"
                    id="us-shares"
                    name="marketType"
                    value="us shares"
                    onChange={props.handleChange}
                    className="hidden peer"
                  />
                  <label
                    htmlFor="us-shares"
                    className="inline-flex items-center w-[114px] p-[6px] cursor-pointer text-[#95A7B8] bg-white border border-[#95A7B8] rounded-[60px]  hover:bg-primary hover:text-white peer-checked:border-primary peer-checked:text-white peer-checked:bg-primary"
                  >
                    <div className="w-full text-center font-[500] text-[14px]">
                      US Shares
                    </div>
                  </label>
                </li>
                <li>
                  <input
                    type="radio"
                    id="indices"
                    name="marketType"
                    value="indices"
                    onChange={props.handleChange}
                    className="hidden peer"
                  />
                  <label
                    htmlFor="indices"
                    className="inline-flex items-center w-[114px] p-[6px] cursor-pointer text-[#95A7B8] bg-white border border-[#95A7B8] rounded-[60px]  hover:bg-primary hover:text-white peer-checked:border-primary peer-checked:text-white peer-checked:bg-primary"
                  >
                    <div className="w-full text-center font-[500] text-[14px]">
                      Indices
                    </div>
                  </label>
                </li>
                <li>
                  <input
                    type="radio"
                    id="au-shares"
                    name="marketType"
                    value="au-shares"
                    onChange={props.handleChange}
                    className="hidden peer"
                  />
                  <label
                    htmlFor="au-shares"
                    className="inline-flex items-center w-[114px] p-[6px] cursor-pointer text-[#95A7B8] bg-white border border-[#95A7B8] rounded-[60px]  hover:bg-primary hover:text-white peer-checked:border-primary peer-checked:text-white peer-checked:bg-primary"
                  >
                    <div className="w-full text-center font-[500] text-[14px]">
                      AU Shares
                    </div>
                  </label>
                </li>
              </ul>
            </div>
            <div className="mt-8">
              <select
                defaultValue={"DEFAULT"}
                onChange={props.handleChange}
                className="w-2/5 border-border mb-4 cursor-pointer text-sm focus:border-gogreen focus:ring-gogreen"
                id="simple-select"
                label="Select Market"
                name="market"
              >
                <option disabled={true} value="DEFAULT">
                  Select Market
                </option>
                {marketType === "forex" ? (
                  <>
                    {/* forex */}
                    {MarketObjs().forexOptions.map((item, index) => {
                      return (
                        <option key={index} value={item.name}>
                          {item.value}
                        </option>
                      );
                    })}
                  </>
                ) : marketType === "metals" ? (
                  <>
                    {/* metals */}
                    {MarketObjs().metalOptions.map((item, index) => {
                      return (
                        <option key={index} value={item.name}>
                          {item.value}
                        </option>
                      );
                    })}
                  </>
                ) : marketType === "indices" ? (
                  <>
                    {/* indices */}
                    {MarketObjs().indicesOptions.map((item, index) => {
                      return (
                        <option key={index} value={item.name}>
                          {item.value}
                        </option>
                      );
                    })}
                  </>
                ) : marketType === "us shares" ? (
                  <>
                    {/* US shares */}
                    {MarketObjs().sharesOptions.map((item, index) => {
                      return (
                        <option key={index} value={item.name}>
                          {item.value}
                        </option>
                      );
                    })}
                  </>
                ) : marketType === "au-shares" ? (
                  <>
                    {/* AU shares */}
                    {MarketObjs().auSharesOptions.map((item, index) => {
                      return (
                        <option key={index} value={item.name}>
                          {item.value}
                        </option>
                      );
                    })}
                  </>
                ) : (
                  ""
                )}
              </select>
            </div>
            <div className="mt-8">
              <h3 className="font-display text-base font-bold text-sub mb-1">
                Price Details
              </h3>
              <p className="mb-6">Enter the pricing details below</p>
              <div>
                <label className="font-body text-sm font-normal text-sub">
                  Closing Price
                </label>
              </div>
              <input
                className="form-control mb-2 w-[201px] text-sm border-border mt-1.5 focus:border-gogreen focus:ring-gogreen"
                type="number"
                name="closePrice"
                placeholder="0.00000"
                step="0.01"
                onChange={props.handleChange}
              />
            </div>
            <div className="mt-8">
              <div>
                <label className="font-body text-sm font-normal text-sub">
                  Price Direction
                </label>
              </div>

              <ul className="grid gap-1 grid-cols-2 w-max p-1 bg-[#ECF0FC] mt-2">
                <li>
                  <input
                    type="radio"
                    id="up"
                    name="marfketDir"
                    value="up"
                    className="hidden peer"
                    onChange={props.handleChange}
                    defaultChecked
                    required
                  />
                  <label
                    htmlFor="up"
                    className="inline-flex items-center w-[100px] p-[4px] cursor-pointer text-[#6B7980] rounded-[4px] hover:bg-primary hover:text-white peer-checked:border-primary peer-checked:text-white peer-checked:bg-primary"
                  >
                    <div className="w-full text-center font-bold">Up</div>
                  </label>
                </li>
                <li>
                  <input
                    type="radio"
                    id="down"
                    name="marfketDir"
                    value="down"
                    onChange={props.handleChange}
                    className="hidden peer"
                  />
                  <label
                    htmlFor="down"
                    className="inline-flex items-center w-[100px] p-[4px] cursor-pointer text-[#6B7980] rounded-[4px] hover:bg-primary hover:text-white peer-checked:border-primary peer-checked:text-white peer-checked:bg-primary"
                  >
                    <div className="w-full text-center font-bold">Down</div>
                  </label>
                </li>
              </ul>
            </div>
            <div className="input-group mt-2">
              <div className="grid gap-6 mb-6 md:grid-cols-2">
                <div>
                  <label className="font-body text-sm font-normal text-sub w-full">
                    Points Change
                  </label>
                  <input
                    className="form-control mb-2 w-full text-sm border-border mt-1.5 focus:border-gogreen focus:ring-gogreen"
                    type="number"
                    name="pointsChange"
                    placeholder="0.00000"
                    onChange={props.handleChange}
                  />
                </div>
                <div>
                  <label className="font-body text-sm font-normal text-sub w-full">
                    Percentage Change
                  </label>
                  <input
                    className="form-control mb-2 w-full text-sm border-border mt-1.5 focus:border-gogreen focus:ring-gogreen"
                    type="number"
                    name="percentChange"
                    placeholder="00.00"
                    onChange={props.handleChange}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>

        <div className="col-span-7 sm:pl-0 lg:pl-11">
          {/* Preview component */}
          <div
            id="gen-img-preview"
            className={`DisplayImg ${props.data.marketsoverlay} DisplayImgPreview hidden sm:hidden lg:block`}
          >
            <div id="top" className="flex relative justify-start items-center">
              <p className="font-inter">
                Market
                <i className="font-goma ml-2">Movers</i>
              </p>
            </div>

            <div id="left" className="relative">
              <div className="block1">
                <div className="max-w-[343.48px] ml-[43.36px] flex items-top">
                  <div className="mt-[148.86px] absolute bottom-[150px]">
                    <h2
                      className={`text-dark  ${
                        selected ? selected.fontPrev : ""
                      }`}
                    >
                      {selected ? selected.marketName : "Market Name"}
                    </h2>
                    <div className="max-w-[240.12px] h-[34.95px]">
                      <h3 className="text-[#69808D]">
                        {props.data.market ? props.data.market : "Code"}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="block2">
                <div className="max-w-[277.01px] h-[58.9px] ml-[43.36px] flex items-top">
                  <div className="mt-[34.95px]">
                    <h2 className="text-[#3A4C56]">
                      {props.data.closePrice
                        ? props.data.closePrice
                        : "0.00000"}
                    </h2>
                  </div>
                </div>
              </div>
            </div>

            <div id="right" className="relative">
              <div
                className={`text-area flex items-top m-0 ${
                  props.data.marfketDir === "up"
                    ? "bg-[#089981]"
                    : "bg-[#C01415]"
                }`}
              >
                <div className="max-w-[283.48px] h-[139.8px] ml-[23.36px] mt-[34.3px] flex">
                  {props.data.marfketDir === "up" ? (
                    <PriceUp customStyle={"w-[75px] h-[75px]"} />
                  ) : (
                    <PriceDown customStyle={"w-[75px] h-[75px]"} />
                  )}

                  <h2 className="ml-5">
                    {props.data.pointsChange
                      ? props.data.pointsChange
                      : "+0.00000"}
                    <br />
                    {props.data.percentChange
                      ? `${props.data.percentChange}%`
                      : "(+0.0000%)"}
                  </h2>
                </div>
              </div>
              <div className="img-area">
                <img
                  src={selected ? selected.image : props.data.placeholderImg}
                  className="img-fluid"
                  alt=""
                  width="100%"
                />
              </div>
            </div>

            <div
              id="bottom"
              className="flex relative justify-start items-center bg-red-500"
            >
              <img
                src="/go-logo-reverse@2x.png"
                className="ml-[35.95px]"
                alt="go-logo"
                width="299.02px"
              />
            </div>
          </div>
          {/* Generated component */}
          <div className="sr-only">
            <div
              id="gen-img"
              className={`DisplayImg ${props.data.marketsoverlay} lg:block`}
            >
              <div
                id="top"
                className="flex relative justify-start items-center"
              >
                <p className="font-inter">
                  Market
                  <i className="font-goma ml-2">Movers</i>
                </p>
              </div>
              <div id="left" className="relative">
                <div className="block1">
                  <div className="max-w-[438px] ml-[56px] flex items-top">
                    <div className="mt-[230px] absolute bottom-[238px]">
                      <h2
                        className={`text-dark  ${selected && selected.fontGen}`}
                      >
                        {selected ? selected.marketName : "Market Name"}
                      </h2>
                      <div className="max-w-[240.12px] h-[34.95px]">
                        <h3 className="text-[#69808D]">
                          {props.data.market ? props.data.market : "Code"}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="block2">
                  <div className="max-w-[428px] h-[91px] ml-[56px] flex items-top">
                    <div className="mt-[54px]">
                      <h2 className="text-[#3A4C56]">
                        {props.data.closePrice
                          ? props.data.closePrice
                          : "0.00000"}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
              <div id="right" className="relative">
                <div
                  className={`text-area flex items-top m-0 ${
                    props.data.marfketDir === "up"
                      ? "bg-[#089981]"
                      : "bg-[#C01415]"
                  }`}
                >
                  <div className="max-w-[416px] h-[110px] ml-[30px] mt-[53px] flex">
                    {props.data.marfketDir === "up" ? (
                      <PriceUp customStyle={"w-[105px] h-[120px]"} />
                    ) : (
                      <PriceDown customStyle={"w-[105px] h-[120px]"} />
                    )}
                    <h2 className="ml-5">
                      {props.data.pointsChange
                        ? props.data.pointsChange
                        : "+0.00000"}
                      <br />
                      {props.data.percentChange
                        ? `${props.data.percentChange}%`
                        : "(+0.0000%)"}
                    </h2>
                  </div>
                </div>
                <div className="img-area">
                  <img
                    src={selected ? selected.image : props.data.placeholderImg}
                    className="img-fluid"
                    alt=""
                    width="100%"
                  />
                </div>
              </div>
              <div
                id="bottom"
                className="flex relative justify-start items-center bg-red-500"
              >
                <img
                  src="/go-logo-reverse@2x.png"
                  className="ml-[54px]"
                  alt="go-logo"
                  width="462px"
                />
              </div>
            </div>
          </div>
          {/* End of generated component */}
          <div className="button-wrapper">
            <button
              type="button"
              className="btn mt-11 rounded st-4"
              onClick={(e) => {
                props.handleFormReset(e);
              }}
            >
              Reset Preview
            </button>
            <button
              className="btn btn-primary ease-linear transition-all duration-150 rounded st-5"
              type="button"
              onClick={props.handleJpeg}
            >
              Generate Image
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MarketMovers;
