import { useEffect, useState } from "react";
import { Info } from "../../layouts/icons";

export const Next = () => {
  return <span>Next Tip</span>;
};
export const Skip = () => {
  return <span>I've seen enough</span>;
};
export const Back = () => {
  return <span>Back</span>;
};
export const Last = () => {
  return <span>Got it</span>;
};
export const Title = (props) => {
  return (
    <div className="flex justify-between">
      {props.children} <Info />
    </div>
  );
};

export const ValidateRun = (type) => {
  const [run, setRun] = useState(true);
  useEffect(() => {
    if (
      localStorage.getItem("newUser") === "false" ||
      localStorage.getItem(type) === "finished"
    ) {
      setRun(false);
    } else if (!localStorage.getItem("newUser")) {
      setRun(false);
    } else {
      setRun(true);
    }
  }, [type]);

  return run;
};
