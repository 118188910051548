import VerifyAuth from "../../services/firebaseActions/verifyAuth";
import { AsanaForms } from "../../utilities/objects";
import { ArrowBox } from "../../layouts/icons";
import { LoginModal } from "../auth/loginModal";

export default function Options() {
  const isLoggedin = VerifyAuth();

  return (
    <div className="w-full px-4 py-16 flex items-start justify-center text-center lg:text-start lg:justify-start relative">
      {!isLoggedin && (
        <div
          className="absolute z-10 md:w-full max-w-sm px-4 py-16"
          style={{ height: "420px" }}
        >
          <center>
            <h5 className="text-2xl font-bold tracking-tight text-gray-900">
              Sign in Required
            </h5>
            <p className="font-normal text-gray-700 mt-5">
              To access our request forms you need to sign in with your company
              Gmail account
            </p>
            <div className="w-full google-btn mt-20">
              <LoginModal btnText={"Continue with google"} googleBtn={true} />
            </div>
          </center>
        </div>
      )}
      <div
        className={
          isLoggedin
            ? "w-full max-w-md space-y-2 z-5"
            : "w-full max-w-md space-y-2 z-5 blur"
        }
      >
        {AsanaForms().map((item, index) => {
          return (
            <div
              key={index}
              className="flex justify-center text-center lg:text-start lg:justify-start "
            >
              <a
                disabled={isLoggedin ? false : true}
                id="options"
                href={item.url}
                target="_blank"
                rel="noopener noreferrer"
                className={
                  isLoggedin
                    ? `relative sm:w-[28rem] flex text-start cursor-pointer items-center rounded-lg px-5 py-4 shadow-md bg-white transition ease-in-out delay-50 hover:-translate-y-1 hover:scale-110 border border-gray-300 ${item.color}`
                    : "pointer-events-none relative sm:w-[28rem] flex text-start items-center rounded-lg px-5 py-4 shadow-md bg-white border border-gray-300"
                }
              >
                <div className="shrink-0 mr-5">{item.icon}</div>
                <div className="flex w-full items-center justify-between">
                  <div className="flex items-center">
                    <div className="text-sm">
                      <p className="font-medium">{item.title}</p>

                      <p className="inline text-gray-400 text-sm">
                        {item.description}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="shrink-0 ml-[2.3rem]">
                  <ArrowBox class={"w-5 h-5"} />
                </div>
              </a>
            </div>
          );
        })}
      </div>
    </div>
  );
}
