import React from "react";
import { DocText, File, Table } from "../icons";

function CardList(props) {
  let fileType = props.value.file_name
    ? props.value.file_name.split(".").pop()
    : "";
  return (
    <div className="flex items-top lg:items-center text-start space-x-4">
      <div className="shrink-0 bg-green-50 rounded-lg p-2">
        {fileType === "pdf" ? (
          <File />
        ) : fileType === "txt" ? (
          <DocText />
        ) : fileType === "csv" ? (
          <Table />
        ) : (
          <File />
        )}
      </div>
      <div className="min-w-0 flex-1">
        <a
          href={props.value.file}
          target="_blank"
          rel="noreferrer"
          className="block max-w-max text-sm font-medium text-gray-900 dark:text-white"
        >
          <p className="truncate">{props.value.file_name}</p>
        </a>

        <p className="truncate text-sm text-gray-500 dark:text-gray-400">
          {props.value.user_id}
        </p>
      </div>
      <div className="min-w-0 md:w-3/6 lg:w-2/6 xl:w-1/6 flex justify-end">
        <p className="truncate text-sm text-gray-500 dark:text-gray-400">
          {" "}
          {new Date(props.value.createdAt).toLocaleDateString()}
          <span>
            <br />
            {new Date(props.value.createdAt).toLocaleTimeString("en-US", {
              timeStyle: "short",
              hour12: true,
            })}
          </span>
        </p>
      </div>
    </div>
  );
}

export default CardList;
