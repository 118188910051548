function Generated(props) {
  return (
    <div className="sr-only">
      <div id="gen-img" className={`DisplayImg DisplayImgPreview lg:block`}>
        <img
          src="/Logo-Green-rgb.png"
          className="img-fluid absolute z-20 top-8 right-8"
          alt=""
          width="85px"
        />
        <div className="grid grid-cols-[auto_660px]">
          {props.content ? (
            <div
              className={`${props.subTextColor} content min-h-full h-[720px] p-16 flex items-end`}
            >
              <h2
                className={`text-white font-goma text-[80px] leading-[96px] ${props.fontStyle}`}
              >
                {props.dataContent}
              </h2>
            </div>
          ) : (
            <div
              className={`${props.subTextColor} content min-h-full h-[720px] p-16 flex items-end`}
            >
              <h2
                className={`text-white font-goma text-[80px] leading-[96px] ${props.fontStyle}`}
              >
                Enter your thumbnail title
              </h2>
            </div>
          )}
          <div className="block h-[360px] min-h-full">
            <img
              src={props.randomImg}
              className="img-fluid img-thumbnail"
              alt=""
              width="100%"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Generated;
