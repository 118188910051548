import { ContentPanel } from "../components/home/content-panel";
import { Hero } from "../layouts/hero";
import { MainLayout } from "../layouts/mainLayout";

function Main() {
  return (
    <MainLayout>
      <div className="main">
        <Hero
          title={"Powering creation together."}
          desc={
            <>
              GO Marketing makes it easier for teams to achieve their collective
              goals, create professional GO Markets branded assets and stay
              informed with features across our sites
            </>
          }
          height={"h-[33rem]"}
          logoWidth={"w-10/12"}
        />
        <ContentPanel />
      </div>
    </MainLayout>
  );
}

export default Main;
