export const images = [
  {
    id: 1,
    name: "Metatrader",
    image: "/650-metatrader.jpg",
  },
  {
    id: 2,
    name: "Low FX",
    image: "/650x400-Low-FX.jpg",
  },
  {
    id: 3,
    name: "GO for Gold",
    image: "/650x400-header-go-for-gold.jpg",
  },
  {
    id: 4,
    name: "Safety Net",
    image: "/650x400-protection.jpg",
  },
  {
    id: 5,
    name: "Cash Returns",
    image: "/650-cash-returns.jpg",
  },
  {
    id: 6,
    name: "Foreign Exchange",
    image: "/650-foreign-exchange.jpg",
  },
  {
    id: 7,
    name: "Gold Bullion",
    image: "/650-gold-bullion.jpg",
  },
  {
    id: 8,
    name: "Meeting",
    image: "/650-meeting.jpg",
  },
  {
    id: 9,
    name: "Price Tickers",
    image: "/650-price-tickers.jpg",
  },
  {
    id: 10,
    name: "Traders Group",
    image: "/650-traders-group.jpg",
  },
  {
    id: 11,
    name: "Trading Boost",
    image: "/650-trading-boost.jpg",
  },
  {
    id: 12,
    name: "US Dollar",
    image: "/650-us-dollar.jpg",
  },
  {
    id: 13,
    name: "Winner",
    image: "/650-winner.jpg",
  },
  {
    id: 14,
    name: "Wharf",
    image: "/650x400-share-wharf.jpg",
  },
  {
    id: 15,
    name: "Globe",
    image: "/650x400-aus-globe.jpg",
  },
  {
    id: 16,
    name: "Bitcoin",
    image: "/650x400-bitcoin-1.jpg",
  },
  {
    id: 17,
    name: "Charts",
    image: "/650x400-charts-2.jpg",
  },
  {
    id: 18,
    name: "Energy",
    image: "/650x400-energy-1.jpg",
  },
  {
    id: 19,
    name: "Symbols",
    image: "/650x400-fx-symbols.jpg",
  },
  {
    id: 20,
    name: "Ticker",
    image: "/650x400-fx-ticker.jpg",
  },
  {
    id: 21,
    name: "Market",
    image: "/650x400-market-vola.jpg",
  },
  {
    id: 22,
    name: "Oil",
    image: "/650x400-oil-1.jpg",
  },
  {
    id: 23,
    name: "Pound",
    image: "/650x400-pound.jpg",
  },
  {
    id: 24,
    name: "Wall Street",
    image: "/650x400-wall-street.jpg",
  },
  {
    id: 25,
    name: "Tesla",
    image: "/650x400-tlsa-reporting.jpg",
  },
  {
    id: 26,
    name: "Amazon",
    image: "/650x400-amzn-reporting.jpg",
  },
  {
    id: 27,
    name: "US Shares",
    image: "/650x400-us-shares.jpg",
  },
  {
    id: 28,
    name: "$0 Commission US Shares",
    image: "/650x400-us-zero-comms.jpg",
  },
  {
    id: 29,
    name: "Google",
    image: "/650x400-email-header-GOOG.jpg",
  },
  {
    id: 30,
    name: "Microsoft",
    image: "/650x400-email-header-MSFT.jpg",
  },
  {
    id: 31,
    name: "Apple",
    image: "/650x400-email-header-AAPL.jpg",
  },
  {
    id: 32,
    name: "Meta",
    image: "/650x400-email-header-META.jpg",
  },
];
