function Preview(props) {
  return (
    <div className="sr-only lg:not-sr-only">
      <p className="block w-fit m-auto text-sub text-sm pb-6">
        Preview is 100% of actual size
      </p>
      <div id="gen-img-wrapper">
        <span className="block w-fit m-auto text-gogreen align-center pt-7 pb-4 text-xs underline">
          To view this email as a webpage, click here
        </span>
        <div
          id="gen-img-header"
          className="block bg-white m-auto w-[650px] pt-10 pb-7"
        >
          {props.overlayType === "overlay1" && (
            <img
              src="/go-logo@2x.png"
              alt="GO Markets"
              className="m-auto"
              width="250"
            />
          )}
        </div>
        <div id="gen-img" className={`DisplayImg lg:block w-[650px] st-3`}>
          {props.hasImage ? (
            <img
              src={props.randomImg}
              className="img-fluid img-thumbnail"
              alt=""
              width="100%"
            />
          ) : (
            <div className="bg-main w-[650px] h-[400px]"></div>
          )}
          {props.topTxt ? (
            <h2 className={`topText ${props.fontStyle} ${props.topTextSize}`}>
              {props.topText}
            </h2>
          ) : (
            ""
          )}
        </div>
        <div
          id="gen-img-footer"
          className="block bg-white m-auto w-[650px] pt-11"
        >
          <h2 className="text-center font-goma text-main text-5xl leading-[60px] font-extrabold px-10 mb-14">
            Email header or title goes here in your html build
          </h2>
          <p className="pl-[70px]">Dear client,</p>
        </div>
        <div className="block m-auto w-[650px] h-[60px] bg-gradient-to-b from-[#ffffff] to-[#F7F8FA] ..."></div>
      </div>
    </div>
  );
}

export default Preview;
