import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import RefreshAuth from "./refreshAuth";
/**
 * Checks for currently logged in user if any.
 *
 * Note: This API is requires firebase SDK setup on the project.
 *
 * @see https://www.npmjs.com/package/go-auth-lib
 */
const VerifyAuth = () => {
  const auth = getAuth();
  const [isLoggedin, setIsLoggedin] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        RefreshAuth(user);
        setIsLoggedin(true);
      } else {
        // console.log("no user logged in...");
        localStorage.clear();
        Cookies.remove("token");
        Cookies.remove("profImg");
        Cookies.remove("userId");
        setIsLoggedin(false);
      }
    });
    return unsubscribe;
  }, [auth]);
  return isLoggedin;
};

export default VerifyAuth;
