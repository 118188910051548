import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import ImgMain from "./pages/socialMedia";
import EmailImgMain from "./pages/emailGenerator";
import YoutubeImgMain from "./pages/ytThumbnail";
import FileUploader from "./pages/fileUploader";
import "./styles/App.scss";
import ProtectedRoute from "./routes/protectedRoute";
import { useEffect, useState } from "react";
import VerifyAuth from "./services/firebaseActions/verifyAuth";
import Main from "./pages/main";
import { UploadSection } from "./components/file-uploader/uploadSection";
import StatusProvider from "./context/statusProvider";
import ResponseProvider from "./context/responseProvider";
import {
  DIVIDEND_FEED,
  EMAIL_IMAGE_GENERATOR,
  GENERAL,
  IMAGE_GENERATOR,
  LEGAL_DOCUMENTS,
  TRADING_HOURS,
  UPLOAD,
  YT_THUMBNAIL_CREATOR,
} from "./routes/routes";
import UploadProvider from "./context/UploadProvider";

function App() {
  const [locationState, setLocationState] = useState("");
  const { state } = useLocation();

  useEffect(() => {
    if (state) {
      const { stateObj } = state;
      if (stateObj) {
        setLocationState(stateObj.id);
      }
    }
  }, [state]);

  VerifyAuth();

  return (
    <>
      <Routes>
        <Route>
          <Route path="/" element={<Main />} />
          <Route
            path={`/${UPLOAD}`}
            element={
              <ProtectedRoute>
                <StatusProvider>
                  <ResponseProvider>
                    <UploadProvider>
                      <FileUploader />
                    </UploadProvider>
                  </ResponseProvider>
                </StatusProvider>
              </ProtectedRoute>
            }
          >
            <Route path={DIVIDEND_FEED} element={<UploadSection />} />
            <Route path={GENERAL} element={<UploadSection />} />
            <Route path={TRADING_HOURS} element={<UploadSection />} />
            <Route path={LEGAL_DOCUMENTS} element={<UploadSection />} />
          </Route>

          {/* testing - comment before merging to main */}
          {/* <Route
            path="/upload/test"
            element={
              <ProtectedRoute>
                <PrivateRoute>
                  <FileUploader />
                </PrivateRoute>
              </ProtectedRoute>
            }
          /> */}
          {/* End of testing route */}
          <Route
            path={`/${IMAGE_GENERATOR}/:id`}
            element={
              <ProtectedRoute>
                <ImgMain locationState={locationState} />
              </ProtectedRoute>
            }
          />
          <Route
            path={`/${EMAIL_IMAGE_GENERATOR}/*`}
            element={
              <ProtectedRoute>
                <EmailImgMain />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route
          path={`/${YT_THUMBNAIL_CREATOR}/*`}
          element={
            <ProtectedRoute>
              <YoutubeImgMain />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </>
  );
}

export default App;
