import Cookies from "js-cookie";
import { LoginModal } from "../components/auth/loginModal";

/**
 * Children components will be accessible to only authorized users.
 *
 * Note: Protected route must be wrapped in react router.
 *
 * @see https://www.npmjs.com/package/go-auth-lib
 */
const ProtectedRoute = ({ children }) => {
  const isAuthenticated = Cookies.get("userId");

  return isAuthenticated ? children : <LoginModal navigate="/" />;
};

export default ProtectedRoute;
