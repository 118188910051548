import React, { createContext, useState } from "react";

export const StatusContext = createContext({
  updateAPI: {},
  handleApi: () => {},
});

function StatusProvider({ children }) {
  const [updateAPI, setUpdateApi] = useState(false);

  const handleApi = (bool) => {
    setUpdateApi(bool);
  };

  return (
    <StatusContext.Provider value={{ updateAPI, handleApi }}>
      {children}
    </StatusContext.Provider>
  );
}

export default StatusProvider;
