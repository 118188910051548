import { Button, Tooltip } from "flowbite-react";
import React, { useContext, useEffect, useState } from "react";
import { Copy } from "../icons";
import { StorageContext } from "../../components/file-uploader/uploadSection";
import { C_GENERAL, C_LEGAL_DOCUMENTS } from "../../constants/types";

function CopyClipboard(props) {
  const [copyText, setCopyText] = useState("Copy Link");
  const [data, setData] = useState();
  const { legalCategory } = useContext(StorageContext);

  useEffect(() => {
    if (props.data && props.formType === C_LEGAL_DOCUMENTS) {
      setData(props.data);
    } else if (props.data && props.formType === C_GENERAL) {
      setData(props.data);
    } else {
      if (props.data[0] && props.data[0][props.formType]) {
        setData(props.data[0][props.formType]);
      }
    }
  }, [props.data, props.formType, legalCategory]);

  const copyPath = () => {
    navigator.clipboard.writeText(data.file);
    setCopyText("Url copied");
    setTimeout(() => {
      setCopyText("Copy link");
    }, 2000);
  };

  return (
    <div id="link" className={props.customStyle}>
      <Tooltip content={copyText} trigger="hover" placement="left">
        <Button
          disabled={data ? false : true}
          onClick={() => {
            copyPath();
          }}
        >
          <Copy />
          Copy Link
        </Button>
      </Tooltip>
    </div>
  );
}

export default CopyClipboard;
