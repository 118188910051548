import React, { useEffect } from "react";
import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";

import { LanguagePanel } from "../../../layouts/imgGenerator/language-panel";
import {
  DetectLanguage,
  TranslateInput,
} from "../../../services/firebaseActions/language";
import { LanguageFont } from "../../../utilities/utilityService";
import {
  images,
  images_overlay2,
} from "../../../utilities/imgGenerator/globalObjs";

const initialState = {
  id: 20,
  name: "Default",
  image: "/global/1200x628-default-base.jpg",
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Global(props) {
  const [selected, setSelected] = useState([]);
  const [imageCategory, setImageCategory] = useState();
  const [CtaCheck, setCtaCheck] = useState(false);

  //image data from props
  const overlayType = props.data.globaloverlay;
  const topText = props.data.topText;
  const bottomText = props.data.bottomText;

  //translator
  const translateTop = TranslateInput(topText, "en", props.data.lang);
  const translateBottom = TranslateInput(bottomText, "en", props.data.lang);
  //detector
  const detectLang = DetectLanguage(topText);
  //font
  const { fontStyle, btmFontStyle } = LanguageFont(detectLang);

  useEffect(() => {
    if (overlayType === "overlay1") {
      setImageCategory(images);
      setSelected(images[19]);
    } else {
      setImageCategory(images_overlay2);
      setSelected(images_overlay2[19]);
    }
  }, [overlayType]);

  return (
    <div>
      <div className="grid grid-cols-12 gap-8 sm:px-4">
        <div className="col-span-12 sm:col-span-12 lg:col-span-5">
          <p className="mb-6">
            Create and customise your image by using the fields below
          </p>

          <form>
            <div className="image-details">
              <h3 className="font-display text-lg font-bold text-sub mb-7">
                Image Settings
              </h3>

              <div className="image-settings">
                <div className="flex justify-left">
                  <label
                    id="demo-radio-buttons-group-label"
                    className="font-body text-sm font-normal text-sub mr-9"
                  >
                    Image size
                  </label>
                  <div className="form-check form-check-inline mr-5">
                    <input
                      className="form-check-input form-check-input rounded-full h-4 w-4 text-gogreen bg-gray-100 border-gray-300 focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 float-left mr-2 mt-1 align-top cursor-pointer"
                      type="radio"
                      name="globaloverlay"
                      id="inlineRadio1"
                      value="overlay1"
                      defaultChecked
                      onChange={props.handleChange}
                    />
                    <label
                      className="form-check-label inline-block font-body text-sm font-normal text-sub"
                      htmlFor="inlineRadio10"
                    >
                      1200x628
                    </label>
                  </div>
                  <div className="form-check form-check-inline mr-5">
                    <input
                      className="form-check-input form-check-input rounded-full h-4 w-4 text-gogreen bg-gray-100 border-gray-300 focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 float-left mr-2 mt-1 align-top cursor-pointer"
                      type="radio"
                      name="globaloverlay"
                      id="inlineRadio2"
                      value="overlay2"
                      onChange={props.handleChange}
                    />
                    <label
                      className="form-check-label inline-block font-body text-sm font-normal text-sub"
                      htmlFor="inlineRadio20"
                    >
                      1080x1080
                    </label>
                  </div>
                </div>
                <div className="input-group mt-8">
                  <div className="form-group">
                    <Listbox
                      value={selected}
                      onChange={(e) => {
                        setSelected(e);
                        props.handleSelectGlobal(e);
                      }}
                    >
                      {({ open }) => (
                        <>
                          <Listbox.Label className="font-body text-sm font-normal text-sub text-muted mb-3">
                            Select Key Visual
                          </Listbox.Label>
                          <div className="relative mt-1">
                            <Listbox.Button className="relative w-full cursor-pointer border border-border bg-white py-2 pl-3 pr-10 text-left focus:border-gogreen focus:outline-none focus:ring-1 focus:ring-gogreen sm:text-sm">
                              <span className="flex items-center cursor-pointer">
                                <img
                                  src={selected.image}
                                  alt=""
                                  className="h-6 w-6 flex-shrink-0"
                                />
                                <span className="ml-3 block truncate text-sm">
                                  {selected.name}
                                </span>
                              </span>
                              <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth="1.5"
                                  stroke="currentColor"
                                  className="w-6 h-6"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                  />
                                </svg>
                              </span>
                            </Listbox.Button>

                            <Transition
                              show={open}
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="grid grid-cols-4 absolute z-10 mt-1 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {imageCategory
                                  ? [...imageCategory]
                                      .reverse()
                                      .map((imageitem) => (
                                        <Listbox.Option
                                          key={imageitem.id}
                                          className={({ active }) =>
                                            classNames(
                                              active
                                                ? "text-white"
                                                : "text-sub",
                                              "relative cursor-pointer select-none py-2 pl-3 pr-9 w-full"
                                            )
                                          }
                                          value={imageitem}
                                        >
                                          {({ selected, active }) => (
                                            <>
                                              <div className="block">
                                                <img
                                                  src={imageitem.image}
                                                  alt=""
                                                  className="h-20 w-auto flex-shrink-0"
                                                />
                                                <span
                                                  className={classNames(
                                                    selected
                                                      ? "font-semibold"
                                                      : "font-normal",
                                                    "text-sub text-xs mt-2 block truncate"
                                                  )}
                                                >
                                                  {imageitem.name}
                                                </span>
                                              </div>

                                              {selected ? (
                                                <span
                                                  className={classNames(
                                                    active
                                                      ? "text-gogreen"
                                                      : "text-gogreen",
                                                    "absolute inset-y-0 top-3 left-4 flex items-top pr-4 z-50"
                                                  )}
                                                >
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth="1.5"
                                                    stroke="currentColor"
                                                    className="w-6 h-6"
                                                  >
                                                    <path
                                                      strokeLinecap="round"
                                                      strokeLinejoin="round"
                                                      d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                                    />
                                                  </svg>
                                                </span>
                                              ) : null}
                                            </>
                                          )}
                                        </Listbox.Option>
                                      ))
                                  : ""}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </>
                      )}
                    </Listbox>
                  </div>
                </div>
              </div>
            </div>
            <div className="st-2">
              <h3 className="font-display text-lg font-bold text-sub mb-7">
                Image Details
              </h3>
              <div className="image-details">
                <div className="input-group">
                  <label className="font-body text-sm font-normal text-sub w-full">
                    Message
                  </label>
                  <input
                    className="form-control  w-full text-sm border-border mt-1.5 mb-2 focus:border-gogreen focus:ring-gogreen"
                    type="text"
                    name="topText"
                    placeholder="Enter your global campaign messages here"
                    value={props.data.topText}
                    onChange={props.handleChange}
                  />
                  <select
                    defaultValue={"medium"}
                    onChange={props.handleChange}
                    className="w-2/5 border-border mb-4 cursor-pointer text-sm focus:border-gogreen focus:ring-gogreen"
                    id="demo-simple-select"
                    label="Set Font Size"
                    name="topTextSize"
                  >
                    <option disabled={true} value="DEFAULT">
                      Set Font Size
                    </option>
                    <option value="extra-small">46px | Extra Small</option>
                    <option value="small">54px | Small</option>
                    <option value="medium">66px | Medium</option>
                    <option value="large">74px | Large</option>
                    <option value="oversized">88px | Oversized</option>
                  </select>
                </div>
                <div className="input-group">
                  <label className="font-body text-sm font-normal text-sub w-full">
                    Disclaimer and/or Risk Warning
                  </label>
                  <input
                    className="form-control text-sm w-full border-border mt-1.5 focus:border-gogreen focus:ring-gogreen"
                    type="text"
                    name="bottomText"
                    placeholder="Enter the RW here"
                    value={props.data.bottomText}
                    onChange={props.handleChange}
                  />
                </div>
                <div className="input-group inline-block mb-4 mt-11">
                  <input
                    id="checked-checkbox"
                    type="checkbox"
                    className="cursor-pointer inline-block ml-3 float-right w-4 h-4 mt-1 text-gogreen bg-white-100 border-border focus:ring-gogreen dark:focus:ring-gogreen dark:ring-offset-gogreen focus:ring-2 dark:bg-gogreen dark:border-gogreen"
                    onChange={() => {
                      setCtaCheck(!CtaCheck);
                    }}
                  />
                  <label className="font-body text-sm font-normal text-sub w-full">
                    Would you like a cta?
                  </label>
                </div>
                {CtaCheck && (
                  <div className="input-group">
                    <label className="font-body text-sm font-normal text-sub w-full">
                      Call to action label
                    </label>
                    <input
                      className="form-control text-sm w-full border-border mt-1.5 focus:border-gogreen focus:ring-gogreen"
                      type="text"
                      name="ctaText"
                      placeholder="Enter your call to action here"
                      value={props.data.ctaText}
                      onChange={props.handleChange}
                    />
                  </div>
                )}
              </div>
            </div>
            <LanguagePanel
              topText={topText}
              translateTop={translateTop}
              bottomText={bottomText}
              translateBottom={translateBottom}
              handleLanguage={props.handleChange}
              topTextPlaceHolder="Enter your global campaign messages here"
              btmTextPlaceHolder="Disclaimer and/or Risk Warning"
            />
          </form>
        </div>
        <div className="col-span-7 sm:pl-0 lg:pl-6">
          <div
            id="gen-img-preview"
            className={`DisplayImg DisplayImgPreview global ${overlayType} hidden sm:hidden lg:block st-3 !w-[754px] !min-h-[394.59px]`}
          >
            {overlayType === "overlay1" && (
              <img
                src="/go-logo-reverse@2x.png"
                alt=""
                className="gomarkets-logo"
                width="180"
              />
            )}
            <img
              src={
                overlayType === "overlay1"
                  ? props.data.globalImg
                  : props.data.globalImg2
              }
              className={
                overlayType === "overlay1" ? "img-fluid" : "img-fluid h-[100%]"
              }
              alt=""
              width="100%"
            />

            <div className="top_text">
              <h2
                className={
                  overlayType === "overlay2"
                    ? `topText  ${fontStyle} ${props.data.topTextSize} !max-w-[475px] `
                    : `topText !top-10 ${fontStyle} ${props.data.topTextSize} !max-w-[560px] `
                }
              >
                {topText
                  ? topText
                  : " Enter your global campaign messages here"}
              </h2>
              {overlayType === "overlay2" && CtaCheck && (
                <div className="cta-label">
                  <span className="relative bg-primary top-[40px] text-white  px-[23px] py-[11px] rounded">
                    {props.data.ctaText ? props.data.ctaText : " CTA LABEL"}
                  </span>
                </div>
              )}
            </div>

            <div className="bottom_text">
              {overlayType === "overlay1" && CtaCheck && (
                <div className="cta-label">
                  <span className="relative bg-primary bottom-[18px] text-white px-[23px] py-[11px] rounded">
                    {props.data.ctaText ? props.data.ctaText : " CTA LABEL"}
                  </span>
                </div>
              )}
              {overlayType === "overlay2" && (
                <img
                  src="/go-logo-reverse@2x.png"
                  alt=""
                  className="!relative gomarkets-logo !left-0 !bottom-[60px] !max-w-[240px]"
                />
              )}
              <p className={`font-body ${btmFontStyle} max-w-[537.22px]`}>
                {bottomText
                  ? bottomText
                  : " Lorem ipsum me dolor standard risk warning and/or addtional disclaimer for T&C's based message"}
              </p>
            </div>
          </div>

          {/* Generated component */}
          <div className="sr-only">
            <div
              id="gen-img"
              className={`DisplayImg global ${overlayType} lg:block`}
            >
              {overlayType === "overlay1" && (
                <img
                  src="/go-logo-reverse@2x.png"
                  alt=""
                  className="gomarkets-logo"
                  width="400"
                />
              )}
              <img
                src={
                  overlayType === "overlay1"
                    ? props.data.globalImg
                    : props.data.globalImg2
                }
                className={
                  overlayType === "overlay2"
                    ? "img-fluid h-[100%]"
                    : "img-fluid"
                }
                alt=""
                width="100%"
              />

              <div className="top_text">
                <h2
                  className={
                    overlayType === "overlay2"
                      ? `topText ${props.data.topTextSize} ${fontStyle} !max-w-[758px]`
                      : `topText ${props.data.topTextSize}  ${fontStyle} !max-w-[880px]`
                  }
                >
                  {topText}
                </h2>
                {overlayType === "overlay2" && CtaCheck && (
                  <div className="cta-label w-max">
                    <div className="relative bg-primary top-[80px] text-white rounded px-[23px] py-[11px] w-max">
                      {props.data.ctaText ? props.data.ctaText : " CTA LABEL"}
                    </div>
                  </div>
                )}
              </div>

              <div className="bottom_text">
                {overlayType === "overlay1" && CtaCheck && (
                  <div className="cta-label w-max">
                    <div className="relative w-max bg-primary bottom-[30px] px-[23px] py-[11px] text-white rounded">
                      {props.data.ctaText ? props.data.ctaText : " CTA LABEL"}
                    </div>
                  </div>
                )}
                {overlayType === "overlay2" && (
                  <img
                    src="/go-logo-reverse@2x.png"
                    alt=""
                    className="!relative gomarkets-logo !left-0 !bottom-[60px] !max-w-[400px]"
                  />
                )}
                <p
                  className={`font-body ${btmFontStyle} font-normal bottomText max-w-[724.56px]`}
                >
                  {bottomText}
                </p>
              </div>
            </div>
          </div>
          {/* End of generated component */}
          <div className="button-wrapper">
            <button
              type="button"
              className="btn mt-11 rounded st-4"
              onClick={(e) => {
                setSelected(initialState);
                props.handleFormReset(e);
              }}
            >
              Reset Preview
            </button>
            <button
              className="btn btn-primary ease-linear transition-all duration-150 rounded"
              type="button"
              onClick={props.handleJpeg}
            >
              Generate Image
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Global;
