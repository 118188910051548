import { YoutubeImgGenerator } from "../components/youtube-thumbnail-creator/ImgGenerator";
import { MainLayout } from "../layouts/mainLayout";

function Main() {
  return (
    <MainLayout>
      <div className="bg-customPink pt-12 pb-16 mb-12 px-2 sm:px-4">
        <div className="container mx-auto px-2 lg:px-12">
          <div className="grid grid-cols-12 sm:px-4">
            <div className="col-span-6 flex">
              <div className="my-auto">
                <h1 className="font-display text-5xl leading-tight font-extrabold text-main">
                  Youtube Thumbnail Creator
                </h1>
                <p className="font-body text-lg font-normal text-sub mt-6">
                  Create eye catching thumbnails for your channels.
                </p>
              </div>
            </div>
            <div className="col-span-6">
              <div className="flex justify-center">
                <img
                  src="/youtube-header-img.png"
                  className="img-header"
                  alt=""
                  width="450"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <YoutubeImgGenerator />
    </MainLayout>
  );
}

export default Main;
