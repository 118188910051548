import { Dialog, Transition } from "@headlessui/react";
import { Button } from "flowbite-react";
import { Fragment, useEffect } from "react";
import { GoogleIcn } from "./icons";
import { useHandleModal } from "../hooks/useHandleModal";

export const CustomModal = ({
  btnText,
  googleBtn,
  isLoading,
  modalRef,
  openRef,
  isIndex,
  customBtn,
  children,
}) => {
  const { isOpen, closeModal, openModal } = useHandleModal(false);

  useEffect(() => {
    modalRef.current = closeModal;
    if (openRef) {
      openRef.current = openModal;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalRef.current, openRef]);

  return (
    <>
      {isIndex ? (
        googleBtn ? (
          <Button onClick={openModal}>
            <span className="inline-flex items-center justify-center">
              <GoogleIcn />
              {btnText}
            </span>
          </Button>
        ) : (
          <div className="theme-variant">
            <Button onClick={openModal}>{btnText}</Button>
          </div>
        )
      ) : (
        customBtn
      )}

      <Transition
        appear
        show={!isIndex && isIndex !== undefined ? true : isOpen}
        as={Fragment}
      >
        <Dialog
          as="div"
          className="relative z-10"
          onClose={isLoading ? openModal : closeModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all ">
                  {children}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
